import React from 'react'
import Dashboard from './Dashboard'
import Cleaning from './Cleaning'
import Insertion from './Insertion'
import { CurrentBannerRequestProvider } from 'contexts/CurrentBannerRequest'
import Admin from './Admin'
import Restore from './Restore'
import { HasAccessToComponent } from 'components'
import { UserRoles } from 'consts'
import UserActions from './UserActions'

const index: React.FC<{}> = () => {
  return (
    <>
      <CurrentBannerRequestProvider>
        <HasAccessToComponent
          permittedRoles={[UserRoles.BANNER_USER, UserRoles.ADMIN]}
          exact
          path="/banners/dashboard"
          component={Dashboard}
        />
        <HasAccessToComponent
          permittedRoles={[UserRoles.BANNER_USER, UserRoles.ADMIN]}
          exact
          path="/banners/cleaning"
          component={Cleaning}
        />
        <HasAccessToComponent
          permittedRoles={[UserRoles.BANNER_USER, UserRoles.ADMIN]}
          exact
          path="/banners/restore"
          component={Restore}
        />
        <HasAccessToComponent
          permittedRoles={[UserRoles.BANNER_USER, UserRoles.ADMIN]}
          exact
          path="/banners/insertion"
          component={Insertion}
        />
        <HasAccessToComponent
          permittedRoles={[UserRoles.ADMIN]}
          exact
          path="/banners/admin"
          component={Admin}
        />
        <HasAccessToComponent
          permittedRoles={[UserRoles.ADMIN]}
          exact
          path="/banners/actions"
          component={UserActions}
        />
      </CurrentBannerRequestProvider>
    </>
  )
}

export default index
