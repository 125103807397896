import React from 'react'
import { Empty } from 'antd'

const EmptyState: React.FC = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Empty
        description="Nothing to show ! Please initiate a search above"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    </div>
  )
}

export default EmptyState
