import React, { useState, useEffect } from 'react'
import { Button, Divider, notification } from 'antd'
import api from 'api'
import BannersList from 'components/Banners/BannersList'
import SearchBar from 'components/Banners/SearchBar'
import { BannerStatus } from 'consts'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import CleaningBannerDetails from 'components/Banners/CleaningBannerDetails'
import { Banner } from 'types'
import { BannersState, useBannersStore } from './store'
import useRetailers from 'hooks/useRetailers'
import useCountries from 'hooks/useCountries'

const Cleaning: React.FC = () => {
  const { data: retailers } = useRetailers()
  const { data: countries } = useCountries()
  const [cleaning, setCleaning] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [detailedBanner, setDetailedBanner] = useState<Banner | null>(null)

  const banners = useBannersStore((state: BannersState) => state.banners)
  const setBanners = useBannersStore((state: BannersState) => state.setBanners)
  const selectedBannersIds = useBannersStore(
    (state) => state.selectedBannersIds,
  )
  const setSelectedBannersIds = useBannersStore(
    (state) => state.setSelectedBannersIds,
  )
  const handleDrawerClose = () => {
    setDrawerOpen(false)
    setDetailedBanner(null)
  }

  const handleDrawerOpen = (banner: Banner) => {
    setDrawerOpen(true)
    setDetailedBanner(banner)
  }

  useEffect(() => {
    setBanners && setBanners([])
  }, [])

  const handleSearch = async (data: any) => {
    // 0 for status ( to clean )
    const banners: Banner[] = await api.banners.get({
      ...data,
      status: BannerStatus.PENDING,
    })
    setBanners && setBanners(_.map(banners, (b) => ({ ...b, selected: false })))
  }

  const handleStatusChange = async (status: BannerStatus) => {
    setCleaning(true)
    const _bannerIds = banners
      .filter((b) => selectedBannersIds.includes(b.id))
      .map((b) => b.image_hash_id)

    await api.banners.status(status, _bannerIds)
    setBanners &&
      setBanners(
        _.filter(banners, (b) => !_bannerIds.includes(b.image_hash_id)),
      )
    setCleaning(false)
    notification['success']({
      message: `${_bannerIds.length} banners have been ${status === BannerStatus.CLEANED ? 'cleaned' : 'approved'
        }`,
      placement: 'bottomRight',
    })
    setSelectedBannersIds([])
  }

  return (
    <>
      <Helmet>
        <title>Cleaning - Banners - Media management - Dataimpact</title>
      </Helmet>
      <BannersList
        showDetails
        showSelectAll
        handleDetailsClick={handleDrawerOpen}
      >
        <Button
          type="danger"
          loading={cleaning}
          disabled={!selectedBannersIds.length}
          style={{ marginRight: '10px' }}
          onClick={() => handleStatusChange(BannerStatus.CLEANED)}
        >
          Clean
        </Button>
        <Button
          type="primary"
          loading={cleaning}
          disabled={!selectedBannersIds.length}
          onClick={() => handleStatusChange(BannerStatus.APPROVED)}
        >
          Approve
        </Button>
        <Divider type="vertical" />
        <SearchBar
          retailers={retailers}
          countries={countries}
          onSearch={handleSearch}
        />
      </BannersList>
      <CleaningBannerDetails
        banner={detailedBanner}
        open={drawerOpen}
        onClose={handleDrawerClose}
      />
    </>
  )
}

export default Cleaning
