import React, { createContext, useState, useContext, useMemo } from 'react'

const CurrentBannerRequestContext = createContext<any>({})

const CurrentBannerRequestProvider: React.FC = (props) => {
  const [currentRequest, setCurrentRequest] = useState<any>({})

  const value = useMemo(
    () => ({ currentRequest, setCurrentRequest }),
    [currentRequest, setCurrentRequest],
  )

  return <CurrentBannerRequestContext.Provider value={value} {...props} />
}

const useCurrentRequest = () => {
  const context = useContext(CurrentBannerRequestContext)
  if (context === undefined) {
    throw new Error(
      `useCurrentRequest must be used within a CurrentBannerRequestProvider`,
    )
  }
  return context
}

export { CurrentBannerRequestProvider, useCurrentRequest }
