import React, { useState, useEffect } from 'react'
import { Drawer, Button, Divider } from 'antd'
import BannerDetailsTable from './Insertion/BannerDetailsTable'
import ImageZoom from '../ImageZoom'
import api from 'api'

const CleaningBannerDetails: React.FC<{
  banner: any
  open: boolean
  onClose: () => void
}> = ({ open, onClose, banner }) => {
  const [bannerToImageZoom, setBannerToImageZoom] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  const handleImageZoom = (picture: string) => {
    setBannerToImageZoom(picture)
    setModalOpen(true)
  }

  return (
    <Drawer title="Banner details" visible={open} onClose={onClose} width={920}>
      {banner && (
        <>
          <Divider />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              maxHeight: '500px',
              overflowY: 'scroll',
              marginBottom: '50px',
            }}
          >
            <div key={banner.hash} style={{ position: 'relative' }}>
              <img
                style={{ maxWidth: '100%', marginBottom: '15px' }}
                src={banner.imageUrl}
              />
              <Button
                size="small"
                icon="zoom-in"
                type="primary"
                onClick={(e) => {
                  e.stopPropagation()
                  handleImageZoom(banner.imageUrl)
                }}
                style={{ position: 'absolute', left: '10px', top: '10px' }}
              />
            </div>
          </div>
          <BannerDetailsTable banner={banner.hash} />
          <Divider />
          <ImageZoom
            open={modalOpen}
            image={bannerToImageZoom}
            onClose={() => setModalOpen(false)}
          />
        </>
      )}
    </Drawer>
  )
}

export default CleaningBannerDetails
