import { Button, notification } from 'antd'
import api from 'api'
import React, { useState } from 'react'

const Cache = () => {
  const [loading, setLoading] = useState(false)
  const flushCache = async () => {
    setLoading(true)
    await api.flushCache()
    setLoading(false)
    notification['success']({
      message: 'Cache cleared',
    })
  }
  return (
    <div>
      <Button onClick={flushCache} loading={loading} size="large" type="danger">
        Flush Cached Groups/Brands
      </Button>
    </div>
  )
}

export default Cache
