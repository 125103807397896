import React from 'react'
import { Table } from 'antd'
import useBannerDetails from 'hooks/useBannerDetails'

const { Column } = Table

const BannerProductsTable: React.FC<{ banner: any }> = ({ banner }) => {
  const { data: bannerDetails, isLoading } = useBannerDetails(banner)

  return (
    <Table
      dataSource={bannerDetails}
      size={'small'}
      loading={isLoading}
      rowKey={(record, index) => `${index}`}
    >
      <Column title="Name" dataIndex="product_name" key="product_name" />
      <Column title="EAN" dataIndex="product_ean" key="product_ean" />
      <Column title="Ref pe" dataIndex="product_id" key="product_id" />
      <Column title="RPC" dataIndex="rpc" key="rpc" />
      <Column
        title="Image"
        dataIndex="product_image"
        key="screenshot_image"
        render={(url) =>
          url ? (
            <a href={url} target="_blank">
              <img src={url} style={{ maxWidth: '350px', height: 'auto' }} />
            </a>
          ) : (
            'N/A'
          )
        }
      />
    </Table>
  )
}

export default BannerProductsTable
