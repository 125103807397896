import React from 'react'
import styled from 'styled-components'
import { Button, Checkbox } from 'antd'
import 'antd/dist/antd.css'
import { useBannersStore } from 'containers/Banners/store'

const Banner: React.FC<{
  banner: any
  selectable?: boolean
  focusable?: boolean
  disabled?: boolean
  onClick?: () => void
  onSelect?: () => void
  onEdit?: () => void
  onZoom?: () => void
  className?: string
  retailer?: string
  showDetails?: boolean
  onDetailsClick?: (banner: any) => void
  selected?: boolean
}> = ({
  banner,
  selectable = true,
  focusable = false,
  selected = false,
  className,
  onSelect,
  onClick,
  onEdit,
  onZoom,
  retailer,
  showDetails,
  onDetailsClick,
}) => {
  const selectedBannersIds = useBannersStore(
    (state) => state.selectedBannersIds,
  )

  return (
    <div className={className}>
      <div className="tools">
        <p className="retailer">{retailer}</p>
        <div>
          <div>
            {selectable && (
              <Checkbox
                value={banner.id}
                autoFocus={focusable}
                checked={selected}
                onChange={(e) => {
                  e.stopPropagation()
                  e.nativeEvent.stopPropagation()
                  onSelect && onSelect()
                }}
              />
            )}
          </div>
          <div>
            <Button
              size="small"
              icon="zoom-in"
              type="primary"
              onClick={(e) => {
                e.stopPropagation()
                onZoom && onZoom()
              }}
            />
            {onEdit && (
              <Button
                disabled={selectedBannersIds.length > 1}
                size="small"
                icon="edit"
                type="primary"
                onClick={(e) => {
                  e.stopPropagation()
                  onEdit()
                }}
              />
            )}
            {showDetails && onDetailsClick && (
              <Button
                size="small"
                icon="plus"
                type="primary"
                onClick={(e) => {
                  e.stopPropagation()
                  onDetailsClick(banner)
                }}
              />
            )}
          </div>
        </div>
      </div>
      {selected && <div className="overlay" onClick={onSelect}></div>}
      <img onClick={onClick} src={banner.imageUrl} alt={banner.id} />
    </div>
  )
}

const StyledBanner = styled(Banner)`
  /* max-height: 260px; */
  min-width: 100px;
  min-height: 100px;
  width: auto;
  margin: 5px;
  max-width: 100%;
  width: fit-content;
  display: inline-flex;
  border: 2px solid ${(props) => (props.selected ? '#ca3034' : '#b1bcbe')};

  img {
    max-width: 100%;
  }

  cursor: pointer;
  position: relative;
  &:hover {
    border-color: #ffbe00;
  }
  div.overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 0, 0, 0.5);
    z-index: 50;
  }

  div.content {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  p.retailer {
    background-color: white;
    padding: 4px;
    border-radius: 4px;
  }

  div.tools {
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    padding: 7px 0px 0 6px;
    right: 0;
    left: 0;
    z-index: 10;
    div {
      button {
        margin-right: 3px;
      }
    }
    .ant-checkbox {
      transform: scale(1.5);
      &.ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ca3034;
        border-color: #1976d2;
      }
    }
  }
`

export default StyledBanner
