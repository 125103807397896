import api from 'api'
import { useQuery } from 'react-query'

const useGroupsBrands = (formData: any) => {
  return useQuery<any[], Error>(
    'groups-brands',
    () => api.groupsBrands.get(formData),
    {
      enabled: false,
    },
  )
}

export default useGroupsBrands
