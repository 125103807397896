import { useEffect } from 'react'

const useArrowNavigation = (
  elementId: string,
  allowNavigation = true,
  excludeZeroIndex = false,
  onSelect?: (params: any) => void,
  isDrawer = false,
) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (
        allowNavigation &&
        ['ArrowRight', 'ArrowLeft', 'Enter'].includes(event.key)
      ) {
        const container = document.getElementById(elementId)
        if (container) {
          const focusableElements = container.querySelectorAll<HTMLElement>(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
          )
          const focusableElementsArray = Array.from(focusableElements)
          const filteredElements = focusableElementsArray
            .filter((element) => {
              if (element.hasAttribute('disabled')) {
                return false
              }
              if (!excludeZeroIndex) {
                return true
              }
              const tabindex = +(element.getAttribute('tabindex') || 0)
              return !!tabindex
            })
            .sort((a, b) => {
              const tabIndexA = a.getAttribute('tabindex') || 0
              const tabIndexB = b.getAttribute('tabindex') || 0
              return +tabIndexA - +tabIndexB
            })

          const focusedIndex = filteredElements.findIndex(
            (el) => el === document.activeElement,
          )
          const isActiveCheckbox =
            document.activeElement?.getAttribute('type') === 'checkbox'
          if (event.key === 'ArrowRight') {
            event.preventDefault()
            const nextIndex =
              focusedIndex === filteredElements.length - 1
                ? 0
                : focusedIndex + 1
            filteredElements[nextIndex]?.focus()
          } else if (event.key === 'ArrowLeft') {
            event.preventDefault()
            const prevIndex =
              focusedIndex === 0
                ? filteredElements.length - 1
                : focusedIndex - 1
            filteredElements[prevIndex]?.focus()
          } else if (event.key === 'Enter' && isActiveCheckbox) {
            event.preventDefault()
            if (isDrawer) {
              const activeElement =
                document.activeElement as HTMLInputElement | null
              const checked = activeElement?.checked
              const name = activeElement?.name
              onSelect && onSelect({ name, value: !checked })
            } else {
              const checkboxValue = document.activeElement?.getAttribute(
                'value',
              ) as string
              onSelect && onSelect(checkboxValue)
            }
          }
        }
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [allowNavigation, elementId, excludeZeroIndex, isDrawer, onSelect])
}

export default useArrowNavigation
