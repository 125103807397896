import { Button, Pagination } from 'antd'
import { Container } from 'components'
import EmptyState from 'components/EmptyState'
import { useBannersStore } from 'containers/Banners/store'
import useRetailers from 'hooks/useRetailers'
import _ from 'lodash'
import React, { useState } from 'react'
import styled from 'styled-components'
import Banner from '../../components/Banners/Banner'
import ImageZoom from '../ImageZoom'
import useArrowNavigation from 'hooks/useArrowNavigation'
import useNavigatePages from 'hooks/useNavigatePages'

const BannersContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`

const StickyContainer = styled(Container)`
  position: sticky;
  z-index: 200;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.75);
  div:nth-child(1) {
    .ant-btn {
      margin-left: 10px;
    }
    span {
      font-size: 1rem;
    }
  }
`

const BannersList: React.FC<{
  onEdit?: (banner: string[]) => void
  showDetails?: boolean
  showSelectAll?: boolean
  handleDetailsClick?: (banner: any) => void
  allowNavigation?: boolean
}> = ({
  children,
  onEdit,
  showSelectAll,
  showDetails,
  handleDetailsClick,
  allowNavigation = true,
}) => {
  const [bannersPerPage, setBannersPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [bannerToImageZoom, setBannerToImageZoom] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  const indexOfLastPost = currentPage * bannersPerPage
  const indexOfFirstPost = indexOfLastPost - bannersPerPage

  const banners = useBannersStore((state) => state.banners)
  const toggleSelectBanner = useBannersStore(
    (state) => state.toggleSelectBanner,
  )
  const selectedBannersIds = useBannersStore(
    (state) => state.selectedBannersIds,
  )

  const setSelectedBannersIds = useBannersStore(
    (state) => state.setSelectedBannersIds,
  )

  const { data: retailers } = useRetailers()
  useArrowNavigation('tabs', allowNavigation, false, (id) => {
    if (id !== undefined) {
      {
        toggleSelectBanner(id)
      }
    }
  })
  useNavigatePages((event: KeyboardEvent) => {
    if (event.shiftKey && ['ArrowRight', 'ArrowLeft'].includes(event.key)) {
      if (event.key === 'ArrowRight') {
        const numberOfPages = Math.ceil(banners.length / bannersPerPage)
        if (currentPage < numberOfPages) {
          setCurrentPage(currentPage + 1)
        }
      } else {
        if (currentPage !== 0) {
          setCurrentPage(currentPage - 1)
        }
      }
    }
  })
  const handlePageChange = (page: any) => {
    setCurrentPage(page)
  }

  const handleSelect = (id: string) => {
    toggleSelectBanner(id)
  }

  const handleMassSelection = (select: boolean) => {
    setSelectedBannersIds(
      select
        ? banners.slice(indexOfFirstPost, indexOfLastPost).map((b) => b.id)
        : [],
    )
  }

  const handleImageZoom = (banner: any) => {
    setBannerToImageZoom(banner.imageUrl)
    setModalOpen(true)
  }

  return (
    <>
      <StickyContainer>
        {showSelectAll && (
          <div>
            <span>{selectedBannersIds.length} selected</span>
            <Button type="primary" onClick={() => handleMassSelection(true)}>
              Select All
            </Button>
            <Button
              type="primary"
              onClick={() => handleMassSelection(false)}
              disabled={!selectedBannersIds.length}
            >
              Unselect All
            </Button>
          </div>
        )}
        <div>{children}</div>
      </StickyContainer>
      <Pagination
        total={banners.length}
        pageSize={bannersPerPage}
        onShowSizeChange={(current, pageSize) => setBannersPerPage(pageSize)}
        current={currentPage}
        onChange={handlePageChange}
        showSizeChanger={true}
        pageSizeOptions={['10', '20', '50', '100', '1000']}
        style={{ textAlign: 'center', marginBottom: '15px' }}
        size="large"
      />
      <BannersContainer id="tabs">
        {!banners.length ? (
          <EmptyState />
        ) : (
          banners
            .slice(indexOfFirstPost, indexOfLastPost)
            .map((b, index) => (
              <Banner
                focusable={!index}
                key={b.id}
                banner={b}
                onZoom={() => handleImageZoom(b)}
                onSelect={() => handleSelect(b.id)}
                selected={selectedBannersIds.includes(b.id)}
                onClick={() => handleSelect(b.id)}
                onEdit={onEdit ? () => onEdit([b.id]) : undefined}
                retailer={retailers[b.retailer_id].Name}
                showDetails={showDetails}
                selectable={!!showSelectAll}
                onDetailsClick={handleDetailsClick}
              />
            ))
        )}
      </BannersContainer>
      <Pagination
        total={_.size(banners)}
        pageSize={bannersPerPage}
        onShowSizeChange={(current, pageSize) => setBannersPerPage(pageSize)}
        current={currentPage}
        onChange={handlePageChange}
        showSizeChanger={true}
        pageSizeOptions={['10', '20', '50', '100', '1000']}
        style={{ textAlign: 'center', marginBottom: '15px' }}
        size="large"
      />
      <ImageZoom
        open={modalOpen}
        image={bannerToImageZoom}
        onClose={() => setModalOpen(false)}
      />
    </>
  )
}

export default BannersList
