import api from 'api'
import { useQuery } from 'react-query'

const useCategories = (country?: number, enabled = true) => {
  return useQuery(['categories', country], () => api.categories.get(country), {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    enabled,
    suspense: true,
  })
}

export default useCategories
