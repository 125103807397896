import styled from 'styled-components'
import Container from './Container'

const SBContainer = styled(Container)`
  display: flex;
  align-items: center;
  gap: 10px;
  label: {
    margin-right: 5px;
  }
  .ant-select,
  .ant-input,
  .ant-btn {
    margin-right: 15px;
    max-width: 300px;
  }
  .ant-select-selection--multiple {
    cursor: pointer;
  }
`

export default SBContainer
