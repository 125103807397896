import React, { useState } from 'react'
import logo from '../assets/di-logo-mini.png'
import jwtDecode from 'jwt-decode'
import { GoogleLogin } from '@react-oauth/google'
import { Redirect } from 'react-router-dom'
import { Col, Row, notification } from 'antd'
import api from 'api'
import { Helmet } from 'react-helmet'

const Login: React.FC = () => {
  const storedUser = localStorage.getItem('accessToken')
  const [isLogged, setIsLogged] = useState<boolean>(storedUser ? true : false)

  const subscribeGoogle = async (response: any) => {
    const token = response.credential

    try {
      await api.user.subscribe(token)

      notification.success({
        message: 'Signup',
        description: 'Signed up successfully, you can now login',
      })
    } catch (error: any) {
      notification.error({
        message: 'Login',
        description:
          error.response.data.error || 'An error occured while login',
      })
    }
  }

  const loginGoogle = async (response: any) => {
    const token = response.credential

    try {
      const res = await api.user.login(token)
      const payload = jwtDecode(token) as Record<string, any>
      localStorage.setItem('accessToken', res.data.accessToken)
      localStorage.setItem('refreshToken', res.data.refreshToken)
      localStorage.setItem(
        'user',
        JSON.stringify({
          name: payload.name,
          imageUrl: payload.picture,
        }),
      )

      localStorage.setItem('roles', JSON.stringify(res.data.roles))
      setIsLogged(true)

      window.location.reload()
    } catch (error: any) {
      notification.error({
        message: 'Login',
        description:
          error.response.data.error || 'An error occured while login',
      })
    }
  }

  return isLogged ? (
    <Redirect to="/" />
  ) : (
    <>
      <Helmet>
        <title>Login - Media management - Dataimpact</title>
      </Helmet>
      <div className="Login">
        <header className="Login-header">
          <img src={logo} className="Login-logo" alt="logo" />
          <p>Media Management</p>
        </header>
        <div>
          <Row gutter={20}>
            <Col span={12}>
              <GoogleLogin
                context="signup"
                text="signup_with"
                onSuccess={subscribeGoogle}
                size="medium"
              />
            </Col>
            <Col span={12}>
              <GoogleLogin
                context="signin"
                text="signin_with"
                onSuccess={loginGoogle}
                size="medium"
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Login
