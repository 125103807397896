import React from 'react'
import { Layout as ALayout, Button, Dropdown, Menu, Avatar, Icon } from 'antd'
import diLogo from 'assets/di-logo-mini.png'
import { googleLogout } from '@react-oauth/google'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import api from 'api'
import { UserRoles } from 'consts'
import hasAccessToRoute from 'utils/hasAccessToRoute'
import { useActiveBannerStore, useBannersStore } from 'containers/Banners/store'

const { Header, Content, Footer } = ALayout

const StyledHeader = styled(Header)`
  display: flex;
  .logo {
    flex: 1;
    img {
      height: 36px;
      width: auto;
    }

    span {
      margin-left: 5px;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .ant-menu {
    padding: 0;
    line-height: 61px;
    background: #e7e7e7;

    .ant-menu-item,
    .ant-dropdown-trigger {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
    }

    .ant-dropdown-trigger {
      .ant-avatar {
        margin-right: 5px;
        width: 24px;
        height: 24px;
      }
    }

    .ant-menu-item {
      padding: 0;
    }
    .ant-dropdown-menu-item a .ant-icon {
      margin-left: 5px;
    }
  }
`

const Layout: React.FC = ({ children }) => {
  const logoutGoogle = async () => {
    let refreshToken = localStorage.getItem('refreshToken')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    await api.user.logout(refreshToken as string)
    googleLogout()
    window.location.reload()
  }
  const setSelectedBannersIds = useBannersStore(
    (state) => state.setSelectedBannersIds,
  )
  const setActiveBannerId = useActiveBannerStore(
    (state) => state.setActiveBannerIds,
  )
  const setBanners = useBannersStore((state) => state.setBanners)

  const resetSelectedBanners = () => {
    setSelectedBannersIds([])
    setActiveBannerId([])
    setBanners([])
  }
  const user: any = JSON.parse(localStorage.getItem('user') || '{}')
  const roles: number[] = JSON.parse(localStorage.getItem('roles') || '[]')

  const userMenu = (
    <Menu>
      <Menu.Item key="logout" onClick={logoutGoogle}>
        <Button type="link" icon="logout" size="small">
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  )

  const bannersMenu = (
    <Menu>
      <Menu.Item key="dashboard">
        <Link to="/banners/dashboard">
          <Icon type="dashboard" />
          Dashboard
        </Link>
      </Menu.Item>
      <Menu.Item key="banner">
        <Link to="/banners/cleaning" onClick={resetSelectedBanners}>
          <Icon type="delete" />
          Cleaning
        </Link>
      </Menu.Item>

      <Menu.Item key="restore">
        <Link to="/banners/restore" onClick={resetSelectedBanners}>
          <Icon type="redo" />
          Restore Banners
        </Link>
      </Menu.Item>

      <Menu.Item key="insertion">
        <Link to="/banners/insertion" onClick={resetSelectedBanners}>
          <Icon type="link" />
          Insertion
        </Link>
      </Menu.Item>

      {hasAccessToRoute(roles, [UserRoles.ADMIN]) && (
        <Menu.Item key="admin">
          <Link to="/banners/admin">
            <Icon type="setting" />
            Admin
          </Link>
        </Menu.Item>
      )}
      {hasAccessToRoute(roles, [UserRoles.ADMIN]) && (
        <Menu.Item key="actions">
          <Link to="/banners/actions">
            <Icon type="setting" />
            User Actions
          </Link>
        </Menu.Item>
      )}
    </Menu>
  )

  return (
    <ALayout style={{ minHeight: '100vh' }}>
      <StyledHeader>
        <div className="logo">
          <img src={diLogo} alt="Data Impact Logo" />
          <span>Media Management</span>
        </div>
        <Menu mode="horizontal">
          {hasAccessToRoute(roles, [
            UserRoles.BANNER_USER,
            UserRoles.ADMIN,
          ]) && (
            <Dropdown trigger={['click', 'hover']} overlay={bannersMenu}>
              <Button data-testid="banners-button" type="link">
                <Icon type="picture" />
                Banners
                <Icon type="down" />
              </Button>
            </Dropdown>
          )}
          {hasAccessToRoute(roles, [UserRoles.ADMIN]) && (
            <Menu.Item key="users">
              <Link to="/users">
                <Icon type="team" />
                Users
              </Link>
            </Menu.Item>
          )}
          <Dropdown trigger={['click', 'hover']} overlay={userMenu}>
            <Button data-testid="logout-button" type="link">
              <Avatar src={user.imageUrl} />
              {user.name}
              <Icon type="down" />
            </Button>
          </Dropdown>
        </Menu>
      </StyledHeader>
      <Content style={{ padding: '0 50px' }}>
        <ALayout>
          <Content>{children}</Content>
        </ALayout>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Data Impact ©2019</Footer>
    </ALayout>
  )
}

export default Layout
