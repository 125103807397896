import React from 'react'
import { Modal } from 'antd'

const { confirm } = Modal

export const confirmResetForm = (onOk: () => void) => {
  confirm({
    title: 'Warning',
    content: 'You have unsaved changes, are you sure you want to leave ?',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk,
  })
}
