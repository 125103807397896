import api from 'api'
import { useQuery } from 'react-query'

const useGroups = (country?: number, enabled = true) => {
  return useQuery(['groups', country], () => api.groups.get(country), {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    enabled,
    suspense: true,
  })
}

export default useGroups
