import api from 'api'
import { useQuery } from 'react-query'

const useBrands = (country?: number, enabled = true) => {
  return useQuery(
    ['brands', country],
    async () => {
      return api.brands.get(country)
    },
    {
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60,
      enabled,
      suspense: true,
    },
  )
}

export default useBrands
