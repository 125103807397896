import React from 'react'
import {
  Select,
} from 'antd'
import { map, filter } from 'lodash'

const { Option } = Select

type Props = { formik: any, countries: any[], retailers: any[] }

const CountryField = ({ formik, countries, retailers }: Props) => {
  return (
    <>
      <label htmlFor="country">Country :</label>
      <Select
        showSearch
        id="country"
        style={{ maxWidth: 300, width: 300 }}
        placeholder="Select country"
        optionFilterProp="children"
        size="large"
        {...formik.getFieldProps('country')}
        onBlur={() => void 1}
        onChange={(value: number) => {
          formik.setFieldValue('country', value)
          formik.setFieldValue('brands', [])
          formik.setFieldValue('groups', [])
          formik.setFieldValue(
            'retailers',
            map(
              filter(retailers, (r) => r.Country == value),
              (r) => r.Id,
            ),
          )
        }}
      >
        {map(countries, (country: any) => (
          <Option value={country.value} key={country.value}>
            {country.name}
          </Option>
        ))}
      </Select>
    </>
  )
}

export default CountryField
