import { Banner } from 'types'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export type BannersState = {
  banners: Banner[]
  selectedBannersIds: string[]
  setBanners: (banners: Banner[]) => void
  toggleSelectBanner: (id: string) => void
  setSelectedBannersIds: (ids: string[]) => void
  updateBanner: (banners: Banner[]) => void
  removeBanner: (banners: Banner[]) => void
}
export type ActiveBannersState = {
  activeBannerIds: string[]
  setActiveBannerIds: (ids: string[]) => void
  isModalVisible: boolean
  setModalVisibility: (visible: boolean) => void
}

export const useBannersStore = create(
  immer<BannersState>((set) => ({
    banners: [],
    selectedBannersIds: [],
    updateBanner: (banners) =>
      set((store) => {
        banners.forEach((_banner) => {
          const bannerIndex = store.banners.findIndex(
            (b) => b.id === _banner.id,
          )
          store.banners[bannerIndex] = {
            ...store.banners[bannerIndex],
            ..._banner,
          }
        })
      }),
    removeBanner: (banners) =>
      set((store) => {
        banners.forEach((_banner) => {
          store.banners.splice(
            store.banners.findIndex((b) => b.id === _banner.id),
            1,
          )
        })
      }),
    setSelectedBannersIds: (ids) =>
      set((state) => {
        state.selectedBannersIds = ids
      }),
    toggleSelectBanner: (id: string) =>
      set((state) => {
        if (state.selectedBannersIds.includes(id)) {
          state.selectedBannersIds.splice(
            state.selectedBannersIds.indexOf(id),
            1,
          )
        } else {
          state.selectedBannersIds.push(id)
        }
      }),
    setBanners: (banners: Banner[]) => set({ banners }),
  })),
)

export const useActiveBannerStore = create(
  immer<ActiveBannersState>((set) => ({
    activeBannerIds: [],
    isModalVisible: false,
    setModalVisibility: (visible) => set({ isModalVisible: visible }),
    setActiveBannerIds: (ids: string[]) =>
      set((state) => {
        state.activeBannerIds = ids
      }),
  })),
)
