import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import ImageZoom from '../../ImageZoom'
import { Banner } from 'types'

const Image = styled.img`
  margin-bottom: 5px;
  width: 100%;
  height: auto;
`

const BannersViewer = ({ banners, ...rest }: { banners: Banner[] }) => {
  const [bannerToImageZoom, setBannerToImageZoom] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  const handleImageZoom = (picture: string) => {
    setBannerToImageZoom(picture)
    setModalOpen(true)
  }

  return (
    <div {...rest}>
      {banners.map((banner) => (
        <div key={banner.id} style={{ position: 'relative' }}>
          <Image src={banner.imageUrl} />
          <Button
            size="small"
            icon="zoom-in"
            type="primary"
            onClick={(e) => {
              e.stopPropagation()
              handleImageZoom(banner.imageUrl)
            }}
            style={{ position: 'absolute', left: '10px', top: '10px' }}
          />
        </div>
      ))}
      <ImageZoom
        open={modalOpen}
        image={bannerToImageZoom}
        onClose={() => {
          setModalOpen(false)
          setBannerToImageZoom('')
        }}
      />
    </div>
  )
}

export default styled(BannersViewer)`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-height: 500px;
  overflow-y: scroll;
`
