import React, { useMemo, useState } from 'react'
import {
  Select,
  Button,
  Table,
  Tag,
  Typography,
  DatePicker,
  Input,
  Statistic,
  Row,
  Col,
} from 'antd'
import SBContainer from 'components/SBContainer'
import { useFormik } from 'formik'
import api from 'api'
import { Container } from 'components'
import moment from 'moment'
import ImageZoom from '../../components/ImageZoom'
import useUsers from 'hooks/useUsers'
import useBrands from 'hooks/useBrands'
import useCategories from 'hooks/useCategories'
import useGroups from 'hooks/useGroups'
import { mapKeys } from 'lodash'
import { BannerStatus } from 'consts'
import transformBrands from 'utils/transformBrands'
import flattenGroups from 'utils/flattenGroups'
import { RangePickerValue } from 'antd/lib/date-picker/interface'
import useBannerTypes from 'hooks/useBannerTypes'

const { RangePicker } = DatePicker

const getStatusLabel = (status: number) => {
  switch (status) {
    case BannerStatus.PENDING:
      return 'PENDING'
    case BannerStatus.CLEANED:
      return 'CLEANED'
    case BannerStatus.APPROVED:
      return 'APPROVED'
    case BannerStatus.INSERTED:
      return 'INSERTED'
    case BannerStatus.PARTIALLY_INSERTED_BRANDS:
      return 'PARTIALLY INSERTED'
  }
}

const { Option } = Select

const mapActions = (
  actions: any[],
  categoriesHM: any,
  formatsHM: any,
  transformedBrands: any,
) => {
  return actions.map((action: any) => {
    const brands = Array.isArray(action.attributes.groups_brands)
      ? flattenGroups(action.attributes.groups_brands).map((g) =>
          transformedBrands[g] ? transformedBrands[g].name : 'N/A',
        )
      : []

    const categories = Array.isArray(action.attributes.categories)
      ? action.attributes.categories.map(
          (cat: string) => categoriesHM[cat]?.name ?? 'N/A',
        )
      : []
    const format = action.attributes?.format
      ? formatsHM[action.attributes.format]?.name ?? 'N/A'
      : null

    const status =
      action.attributes.status === undefined
        ? null
        : getStatusLabel(action.attributes.status)

    return {
      ...action,
      attributes: {
        ...action.attributes,
        categories,
        format,
        brands,
        status,
      },
    }
  })
}
const pairingCases: Record<string, any> = {
  brandsNoGroupsBanners: 'Brand no group',
  noPairGroupsBrandsBanners: 'No pair group brand',
  groupsNoBrandsBanners: 'Group no brand',
  noGroupsNoBrandsBanners: 'No group nor brand',
}
type FormValues = {
  user: string
  period: RangePickerValue
  hash: string
  type: string
}

const initialValues: FormValues = {
  user: '',
  period: [],
  hash: '',
  type: '',
}

const useMasterData = () => {
  const { data: users } = useUsers()
  const { data: brands } = useBrands()
  const { data: groups } = useGroups()
  const { data: categories } = useCategories()
  const { data: bannerTypes } = useBannerTypes()

  return { bannerTypes, users, brands, groups, categories }
}

const UserActions: React.FC = () => {
  const [userActions, setUserActions] = useState<any[]>([])
  const [currentRequest, setCurrentRequest] = useState<any>(null)
  const [stats, setStats] = useState<any>(null)
  const [bannerToImageZoom, setBannerToImageZoom] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const { bannerTypes, users, brands, groups, categories } = useMasterData()
  const categoriesHM = useMemo(() => mapKeys(categories, 'id'), [categories])
  const groupsHM = useMemo(() => mapKeys(groups, 'value'), [groups])
  const brandsHM = useMemo(() => mapKeys(brands, 'value'), [brands])
  const transformedBrands = useMemo(
    () => mapKeys(transformBrands(brands || [], groupsHM), 'id'),
    [brands, groupsHM],
  )

  const formatsHM = useMemo(() => mapKeys(bannerTypes, 'id'), [bannerTypes])

  const handleSubmit = async (values: FormValues) => {
    const actions = await api.userActions.get(values)
    let stats = null
    if (values.user) {
      stats = actions.reduce(
        (cur: any, action: any) => {
          cur[action.type] += 1
          return cur
        },
        [0, 0, 0],
      )

      setCurrentRequest({
        user: {
          mail: users && users.find((u) => u.id === values.user).mail,
          id: values.user,
        },
        period: values.period,
      })
      setStats(stats)
    }

    setUserActions(
      mapActions(actions, categoriesHM, formatsHM, transformedBrands),
    )
  }

  const previwBanner = (url: string) => {
    setBannerToImageZoom(url)
    setModalOpen(true)
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  })

  const columns = [
    {
      title: 'User',
      dataIndex: 'User.mail',
      key: 'user',
      render: (text: any, r: any, i: number) => {
        if ((r.type === 3 && !text) || r.attributes?.partiallyInserted) {
          return 'Automatic'
        }
        return text
      },
    },
    {
      title: 'Banner',
      dataIndex: 'imageUrl',
      key: 'banner',
      render: (text: any, r: any, i: number) => {
        if (!r.image_hash_id) {
          return 'N/A'
        }
        return <Button type="link">{r.image_hash_id}</Button>
      },
      onCell: (record: any, rowIndex: any) => ({
        onClick: () => {
          previwBanner(record.imageUrl)
        },
      }),
    },
    {
      title: 'Action Type',
      dataIndex: 'type',
      key: 'type',
      render: (text: any, r: any, i: number) => {
        return text === 0
          ? 'STATUS CHANGE'
          : text === 1
          ? 'INSERTION'
          : text === 2
          ? 'MAPPING'
          : 'PAIRING'
      },
    },
    {
      title: 'Action date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: any, r: any, i: number) => {
        return moment(text).format('DD/MM/YYYY H:mm:ss')
      },
    },
    {
      title: 'Pairing group brand',
      dataIndex: 'attributes',
      key: 'Pairing group brand',
      render: (text: any, r: any, i: number) => {
        if (r.type !== 3) {
          return 'N/A'
        }
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 5,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
              }}
            >
              <Typography.Text>Case: </Typography.Text>
              <Tag color="blue">{pairingCases[text.case]}</Tag>
            </div>
            {text.case === 'brandsNoGroupsBanners' && (
              <>
                <Tag
                  color="red"
                  style={{ textDecoration: 'line-through' }}
                >{`Group - ${text.group_id}`}</Tag>
                <Tag color="green">{`${
                  groupsHM[text.new.group_id]?.name || 'Group'
                } - ${text.new.group_id}`}</Tag>
                <Tag color="grey">{`${
                  brandsHM[text.brand_id]?.name || text?.brand_name || 'Brand'
                } - ${text.brand_id}`}</Tag>
              </>
            )}
            {text.case === 'noPairGroupsBrandsBanners' && (
              <>
                <Tag color="red" style={{ textDecoration: 'line-through' }}>{`${
                  groupsHM[text.group_id]?.name || 'Group'
                } - ${text.group_id}`}</Tag>
                <Tag color="green">{`${
                  groupsHM[text.new.group_id]?.name || 'Group'
                } - ${text.new.group_id}`}</Tag>
                <Tag color="grey">{`${
                  brandsHM[text.brand_id]?.name || text?.brand_name || 'Brand'
                } - ${text.brand_id}`}</Tag>
              </>
            )}
            {text.case === 'groupsNoBrandsBanners' && (
              <>
                <Tag color="red" style={{ textDecoration: 'line-through' }}>{`${
                  text?.brand_name || 'Brand'
                } - ${text.brand_id}`}</Tag>
                <Tag color="green">{`${
                  brandsHM[text.new.brand_id].name ||
                  text?.brand_name ||
                  'Brand'
                } - ${text.new.brand_id}`}</Tag>
                <Tag color="grey">{`${
                  groupsHM[text.group_id]?.name || 'Group'
                } - ${text.group_id}`}</Tag>
              </>
            )}
            {text.case === 'noGroupsNoBrandsBanners' && (
              <>
                <Tag
                  color="red"
                  style={{ textDecoration: 'line-through' }}
                >{`Group - ${text.group_id}`}</Tag>
                <Tag color="red" style={{ textDecoration: 'line-through' }}>{`${
                  text.brand_name || 'Brand'
                } - ${text.brand_id}`}</Tag>
                <Tag color="green">{`${
                  groupsHM[text.new.group_id].name || 'Group'
                } - ${text.new.group_id}`}</Tag>
                <Tag color="green">{`${
                  brandsHM[text.new.brand_id].name ||
                  text?.brand_name ||
                  'Brand'
                } - ${text.new.brand_id}`}</Tag>
              </>
            )}
          </div>
        )
      },
    },
    {
      title: 'Mapping cat DI',
      dataIndex: 'attributes',
      key: 'Mapping cat DI',
      render: (text: any, r: any, i: number) => {
        const { cat_banners, cats_di } = text
        if (r.type !== 2) {
          return 'N/A'
        }
        return (
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                marginBottom: 5,
              }}
            >
              <Typography.Text>Banner category: </Typography.Text>
              <Tag color="blue">
                {cat_banners === 'na'
                  ? cat_banners
                  : categoriesHM[cat_banners]?.name}
              </Tag>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                alignItems: 'flex-start',
              }}
            >
              {(cats_di || []).map((cat: any) => (
                <Tag color="green">{cat.name}</Tag>
              ))}
            </div>
          </div>
        )
      },
    },
    {
      title: 'Attributes',
      dataIndex: 'attributes',
      key: 'attributes',
      render: (text: any, r: any, i: number) => {
        if (![0, 1].includes(r.type)) {
          return 'N/A'
        }
        return (
          <>
            {text.categories.length !== 0 && (
              <div style={{ marginBottom: '10px' }}>
                <Typography.Text>Categories: </Typography.Text>
                {text.categories.map((cat: string) => (
                  <Tag color="blue">{cat}</Tag>
                ))}
              </div>
            )}
            {text.brands.length !== 0 && (
              <div style={{ marginBottom: '10px' }}>
                <Typography.Text>Brands: </Typography.Text>
                {text.brands.map((cat: string) => (
                  <Tag color="green">{cat}</Tag>
                ))}
              </div>
            )}
            {text.format && (
              <div style={{ marginBottom: '10px' }}>
                <Typography.Text>Format: </Typography.Text>
                <Tag color="gray">{text.format}</Tag>
              </div>
            )}
            {text.status && (
              <div style={{ marginBottom: '10px' }}>
                <Typography.Text>Status: </Typography.Text>
                <Tag color="gray">{text.status}</Tag>
              </div>
            )}
            {text.is_promotion && (
              <div style={{ marginBottom: '10px' }}>
                <Tag color="orange">Is Promotion</Tag>
              </div>
            )}
            {text.is_novelty && (
              <div style={{ marginBottom: '10px' }}>
                <Tag color="purple">Is Novelty</Tag>
              </div>
            )}
            {text.is_special_operation && (
              <div style={{ marginBottom: '10px' }}>
                <Tag color="red">Is Special Operation</Tag>
              </div>
            )}
            {text.is_game && (
              <div style={{ marginBottom: '10px' }}>
                <Tag color="pink">Is Game</Tag>
              </div>
            )}
            {text.partiallyInserted && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 5,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                  }}
                >
                  <Typography.Text>Type: </Typography.Text>
                  <Tag color="blue">{pairingCases[text.case]}</Tag>
                </div>
                {text.case === 'brandsNoGroupsBanners' && (
                  <>
                    <Tag
                      color="red"
                      style={{ textDecoration: 'line-through' }}
                    >{`Group - ${text.group_id}`}</Tag>
                    <Tag color="grey">{`${
                      brandsHM[text.brand_id]?.name ||
                      text?.brand_name ||
                      'Brand'
                    } - ${text.brand_id}`}</Tag>
                  </>
                )}
                {text.case === 'noPairGroupsBrandsBanners' && (
                  <>
                    <Tag color="grey">{`${groupsHM[text.group_id]?.name} - ${
                      text.group_id
                    }`}</Tag>
                    <Tag color="grey">{`${
                      brandsHM[text.brand_id]?.name ||
                      text?.brand_name ||
                      'Brand'
                    } - ${text.brand_id}`}</Tag>
                  </>
                )}
                {text.case === 'groupsNoBrandsBanners' && (
                  <>
                    <Tag
                      color="red"
                      style={{ textDecoration: 'line-through' }}
                    >{`${text?.brand_name || 'Brand'} - ${text.brand_id}`}</Tag>
                    <Tag color="grey">{`${
                      groupsHM[text.group_id]?.name || 'Group'
                    } - ${text.group_id}`}</Tag>
                  </>
                )}
                {text.case === 'noGroupsNoBrandsBanners' && (
                  <>
                    <Tag
                      color="red"
                      style={{ textDecoration: 'line-through' }}
                    >{`Group - ${text.group_id}`}</Tag>
                    <Tag
                      color="red"
                      style={{ textDecoration: 'line-through' }}
                    >{`${text.brand_name || 'Brand'} - ${text.brand_id}`}</Tag>
                  </>
                )}
              </div>
            )}
          </>
        )
      },
    },
  ]

  return (
    <div>
      <SBContainer>
        <Select
          showSearch
          style={{ maxWidth: 300, width: 300, margin: 0 }}
          placeholder="Select user"
          id="user"
          size="large"
          showArrow={true}
          value={formik.values.user || undefined}
          onChange={(value: string) => formik.setFieldValue('user', value)}
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {users &&
            users.map((user: any) => (
              <Option value={user.id} key={user.id}>
                {user.mail}
              </Option>
            ))}
        </Select>
        <RangePicker
          {...formik.getFieldProps('period')}
          style={{ minWidth: 250, width: 250, margin: 0 }}
          onChange={(value: any) => {
            const [startDate, endDate] = value
            const firstMomentOfDay = startDate.clone().startOf('day')
            const lastMomentOfDay = endDate.clone().endOf('day')
            formik.setFieldValue('period', [firstMomentOfDay, lastMomentOfDay])
          }}
          size="large"
          id="period"
        />
        <Select
          style={{ minWidth: 250 }}
          placeholder="Select type"
          size="large"
          onBlur={() => void 1}
          onChange={(value) => {
            formik.setFieldValue('type', value)
          }}
        >
          <Option value={0}>Status change</Option>
          <Option value={1}>Insertion</Option>
          <Option value={2}>Mapping</Option>
          <Option value={3}>Pairing</Option>
        </Select>
        <label htmlFor="hash" style={{ fontSize: '1rem' }}>
          Or:
        </label>
        <Input
          style={{ margin: 0, minWidth: 150 }}
          size="large"
          id="hash"
          onChange={(e) => formik.setFieldValue('hash', e.target.value)}
          placeholder="Hash"
        />
        <Button
          type="primary"
          size="large"
          onClick={() => {
            formik.handleSubmit()
          }}
          loading={formik.isSubmitting}
        >
          Search
        </Button>
      </SBContainer>
      {stats && (
        <Container>
          <Typography.Title level={4}>
            Actions summary for {currentRequest.user.mail}{' '}
            {currentRequest.period &&
              `${moment(currentRequest.period[0]).format(
                'DD/MM/YYYY',
              )} - ${moment(currentRequest.period[1]).format('DD/MM/YYYY')}`}
          </Typography.Title>
          <Row gutter={16}>
            <Col span={6}>
              <Statistic title={'Insertion'} value={stats[1]} />
            </Col>
            <Col span={6}>
              <Statistic title={'Status Change (Cleaning)'} value={stats[0]} />
            </Col>
            <Col span={6}>
              <Statistic title={'DI Mapping'} value={stats[2]} />
            </Col>
          </Row>
        </Container>
      )}
      <Container>
        <Table
          loading={formik.isSubmitting}
          columns={columns}
          dataSource={userActions}
          scroll={{ x: true }}
        />
      </Container>
      <ImageZoom
        open={modalOpen}
        image={bannerToImageZoom}
        onClose={() => setModalOpen(false)}
      />
    </div>
  )
}

export default UserActions
