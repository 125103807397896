import api from 'api'
import { useQuery } from 'react-query'
const useDICategories = (country: number) => {
  return useQuery<any[], Error>(
    ['diCategories', country],
    () => api.diCategories.get(country),
    {
      staleTime: Infinity,
      cacheTime: 1000 * 60 * 60,
    },
  )
}
export default useDICategories
