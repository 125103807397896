import React from 'react'
import { Select } from 'antd'

type Props = { formik: any }

const { Option } = Select

const PurgedField = ({ formik }: Props) => {
  console.log(formik.values)
  return (
    <>
      <label htmlFor="type">Automatic cleaned: </label>
      <Select
        {...formik.getFieldProps('is_purged')}
        onBlur={() => void 1}
        id="type"
        style={{ width: 300 }}
        onChange={(value: number) => formik.setFieldValue('is_purged', value)}
        size="large"
      >
        <Option value={0}>No</Option>
        <Option value={1}>Yes</Option>
      </Select>
    </>
  )
}

export default PurgedField
