import api from 'api'
import { useQuery } from 'react-query'

const useBannerDetails = (id: number) => {
  return useQuery(`${id}`, async () => {
    const data = await api.banners.products(id)
    return data
  })
}

export default useBannerDetails
