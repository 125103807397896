import useBannerTypes from 'hooks/useBannerTypes'
import useBrands from 'hooks/useBrands'
import useCategories from 'hooks/useCategories'
import useCountries from 'hooks/useCountries'
import useGroups from 'hooks/useGroups'
import _ from 'lodash'
import { useMemo } from 'react'
import transformBrands from 'utils/transformBrands'

const useMasterData = (retailerCountries: number[]) => {
  const { data: brands } = useBrands(
    retailerCountries[0],
    retailerCountries.length !== 0,
  )
  const { data: groups } = useGroups(
    retailerCountries[0],
    retailerCountries.length !== 0,
  )
  const { data: categories } = useCategories(
    retailerCountries[0],
    retailerCountries.length !== 0,
  )
  const { data: countries } = useCountries()
  const { data: bannerTypes } = useBannerTypes()

  const transformedBrands = useMemo(() => {
    if (!(groups || brands)) return []
    const _groups: any = _.mapKeys(groups, 'value')
    return transformBrands(brands, _groups)
  }, [brands, groups])

  const _countries: any = useMemo(() => {
    return _.mapKeys(countries, 'value')
  }, [countries])
  const formattedbannertypes = useMemo(() => {
    return bannerTypes.map((bt: any) => ({
      ...bt,
      name: `${bt.name} (${_countries[Number(bt.country)]?.name})`,
    }))
  }, [bannerTypes, _countries])

  return {
    bannerTypes: formattedbannertypes,
    brands: transformedBrands,
    groups,
    categories: categories || [],
  }
}

export default useMasterData
