import React, { useEffect, useMemo, useState } from 'react'
import _, { map, mapKeys } from 'lodash'
import { useFormik } from 'formik'
import { Table, Button, Drawer, Form, Input, Select, notification } from 'antd'
import RVSelect from 'components/RVSelect'
import { matchSorter } from 'match-sorter'
import { useMutation, useQueryClient } from 'react-query'
import useCountries from 'hooks/useCountries'
const { Option } = Select
import { DatePicker } from 'antd'
import api from 'api'
import useBrands from 'hooks/useBrands'
import useGroupsBrands from 'hooks/useGroupBrand'
import useGroups from 'hooks/useGroups'
import uuid from 'uuid-random'
import { Link } from 'react-router-dom'
import Text from 'antd/lib/typography/Text'

const removeUndefinedNull = (obj: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => {
      return value !== undefined && value !== null
    }),
  )
}

const FormDrawer: React.FC<{
  open: boolean
  isLoading: boolean
  onClose: () => void
  onSubmit: (formData: any) => void
  banner: any
  type: string
  brands: any
  groups: any
}> = ({
  open,
  onClose,
  banner,
  type,
  brands = [],
  groups = [],
  onSubmit,
  isLoading,
}) => {
  const formik = useFormik({
    initialValues: {
      brand_id: banner?.brand_id,
      new_brand_id: null,
      group_id: banner?.group_id,
      new_group_id: null,
    } as any,
    enableReinitialize: true,
    onSubmit: async (values, f) => {
      await onSubmit(removeUndefinedNull(values))
      onClose()
      f.resetForm()
    },
  })
  const { brandsOptions, groupsOptions } = useMemo(() => {
    let groupsOptions: any[] = []
    let brandsOptions: any[] = []
    if (banner) {
      if (
        ['brandsNoGroupsBanners', 'noPairGroupsBrandsBanners'].includes(type)
      ) {
        groupsOptions = groups.filter((group: any) =>
          banner.groups_ids.includes(group.value),
        )
        return { brandsOptions, groupsOptions }
      }
      if (type === 'groupsNoBrandsBanners') {
        brandsOptions = brands.filter((brand: any) =>
          banner.brands_ids.includes(brand.value),
        )
        return { brandsOptions, groupsOptions }
      }
      if (type === 'noGroupsNoBrandsBanners') {
        brandsOptions = brands.filter((brand: any) =>
          Object.keys(banner.brands_ids).includes(brand.value),
        )
        if (formik.values.new_brand_id) {
          groupsOptions = groups.filter((group: any) =>
            banner.brands_ids[formik.values.new_brand_id].includes(group.value),
          )
        }
        return { brandsOptions, groupsOptions }
      }
    }
    return { brandsOptions, groupsOptions }
  }, [banner, brands, formik.values.new_brand_id, groups, type])

  return (
    <Drawer
      title="Pair group brand"
      visible={open}
      onClose={onClose}
      width={920}
    >
      <Form
        labelCol={{ xs: { span: 22 }, sm: { span: 6 } }}
        wrapperCol={{ xs: { span: 26 }, sm: { span: 18 } }}
      >
        {['groupsNoBrandsBanners', 'noGroupsNoBrandsBanners'].includes(
          type,
        ) && (
          <Form.Item label="Brands">
            <RVSelect
              options={brandsOptions}
              labelKey={'name'}
              valueKey={'value'}
              onChange={(value) => formik.setFieldValue('new_brand_id', value)}
              value={formik.values.new_brand_id}
            />
          </Form.Item>
        )}
        {[
          'brandsNoGroupsBanners',
          'noPairGroupsBrandsBanners',
          'noGroupsNoBrandsBanners',
        ].includes(type) && (
          <Form.Item label="Groups">
            <RVSelect
              disabled={
                type !== 'noGroupsNoBrandsBanners'
                  ? false
                  : !formik.values.new_brand_id
              }
              options={groupsOptions}
              labelKey={'name'}
              valueKey={'value'}
              onChange={(value) => formik.setFieldValue('new_group_id', value)}
              value={formik.values.new_group_id}
            />
          </Form.Item>
        )}
        <Button
          style={{ float: 'right' }}
          size="large"
          type="primary"
          icon="save"
          loading={isLoading}
          onClick={() => formik.handleSubmit()}
          disabled={!formik.dirty}
          htmlType="submit"
        >
          Save
        </Button>
      </Form>
    </Drawer>
  )
}

const searchInitialValues = {
  country: null,
  lastUpdated: null,
  type: 'brandsNoGroupsBanners',
}

const filterUniqueObjects = (arr: any = []) => {
  const uniqueValues = new Set()
  const uniqueObjects: any[] = []

  arr.forEach((obj: any) => {
    if (!uniqueValues.has(obj.value)) {
      uniqueValues.add(obj.value)
      uniqueObjects.push(obj)
    }
  })

  return uniqueObjects
}
const GroupBrand = () => {
  const formik = useFormik({
    initialValues: searchInitialValues,
    onSubmit: async () => {
      refetch()
    },
  })
  const { isLoading, mutateAsync: updateGroupBrand } = useMutation((formData) =>
    api.groupsBrands.update(formData),
  )
  const queryClient = useQueryClient()
  const { data: countries = [] } = useCountries()
  const {
    isFetching,
    refetch,
    data: groupsBrands,
  } = useGroupsBrands(removeUndefinedNull(formik.values))

  const [searchTerm, setSearchTerm] = useState('')
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const { data: brands } = useBrands(
    formik.values.country ? formik.values.country : undefined,
    !!formik.values.country,
  )
  const { data: groups } = useGroups(
    formik.values.country ?? undefined,
    !!formik.values.country,
  )

  const uniqueGroups = useMemo(() => filterUniqueObjects(groups), [groups])
  const uniqueBrands = useMemo(() => filterUniqueObjects(brands), [brands])

  const uniqueItems = useMemo(() => {
    const items = groupsBrands ?? []
    return items.map((item, i) => ({
      ...item,
      id: uuid(),
    }))
  }, [groupsBrands])
  const brandsHM = useMemo(() => mapKeys(brands, 'value'), [brands])

  useEffect(() => {
    setFilteredItems(
      searchTerm
        ? matchSorter(uniqueItems, searchTerm.trim(), {
            keys: ['image_hash_id'],
            threshold: matchSorter.rankings.CONTAINS,
          })
        : uniqueItems,
    )
  }, [groupsBrands, searchTerm, uniqueItems])

  const columns = [
    {
      title: `Name (${filteredItems.length})`,
      dataIndex: 'brand_name',
      key: 'brand_name',
      render: (brand_name: string) => {
        return (
          <Text style={{ textTransform: 'capitalize' }}>
            {brand_name ?? 'unknown'}
          </Text>
        )
      },
    },
    {
      title: 'Image hash ID',
      dataIndex: 'image_hash_id',
      key: 'image_hash_id',
      render: (image_hash_id: string, record: any) => {
        const params: Record<string, any> = {}
        params['country'] = `${formik.values.country}`
        params['hash'] = image_hash_id
        params['retailer_id'] = record.retailer_id
        const searchParams = new URLSearchParams(params)

        return (
          <div>
            <p>{brandsHM[record.brand_id]?.name}</p>
            <Link
              to={{
                pathname: '/banners/insertion',
                search: searchParams.toString(),
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {image_hash_id}
            </Link>
          </div>
        )
      },
    },
    {
      title: 'Last updated',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (date: any) => {
        const options: any = {
          weekday: 'short',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        }
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
          new Date(date),
        )
        return formattedDate
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      width: 90,
      render: (text: any, record: any) => (
        <Button onClick={() => handleEdit(record)} icon="edit">
          Edit
        </Button>
      ),
    },
  ]

  const handleEdit = (item: any) => {
    if (item) {
      setSelectedItem(item)
    }
    setDrawerOpen(true)
  }

  const onClose = () => {
    setDrawerOpen(false)
    setSelectedItem(null)
  }

  const handleSubmit = async (values: any) => {
    const formData: any = {
      ...removeUndefinedNull(values),
      image_hash_id: selectedItem.image_hash_id,
      type: formik.values.type,
    }
    try {
      await updateGroupBrand(formData)
      queryClient.setQueryData(
        'groups-brands',
        uniqueItems.filter(
          (groupBrand: any) => groupBrand.id !== selectedItem.id,
        ),
      )
      notification['success']({
        message: `Banner updated successfully`,
      })
    } catch (err: any) {
      notification['error']({
        message: err.message,
      })
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '15px',
          gap: '40px',
        }}
      >
        <Input
          addonBefore="Filter"
          placeholder="Enter filter here..."
          size="large"
          style={{ minWidth: 400 }}
          onChange={(e) => {
            setSearchTerm(e.target.value)
          }}
        />

        <Form
          onSubmit={formik.handleSubmit}
          style={{
            display: 'flex',
            gap: '15px',
          }}
        >
          <Select
            showSearch
            id="country"
            style={{ minWidth: 300 }}
            placeholder="Select country"
            optionFilterProp="children"
            size="large"
            onBlur={() => void 1}
            onChange={(value) => {
              formik.setFieldValue('country', value)
            }}
          >
            {map(countries, (country: any) => (
              <Option value={country.value} key={country.value}>
                {country.name}
              </Option>
            ))}
          </Select>
          <Select
            style={{ minWidth: 200 }}
            placeholder="Select table"
            size="large"
            onBlur={() => void 1}
            onChange={(value) => {
              formik.setFieldValue('type', value)
            }}
          >
            <Option value="brandsNoGroupsBanners">Brand no group</Option>
            <Option value="noPairGroupsBrandsBanners">
              No pair group brand
            </Option>
            <Option value="groupsNoBrandsBanners">Group no brand</Option>
            <Option value="noGroupsNoBrandsBanners">No group nor brand</Option>
          </Select>
          <DatePicker
            size="large"
            placeholder="Last updated"
            style={{ minWidth: 200 }}
            onChange={(date) => {
              formik.setFieldValue('lastUpdated', date?.toISOString())
            }}
          />
          <Button
            type="primary"
            size="large"
            icon="search"
            htmlType="submit"
            loading={isFetching}
          >
            Search
          </Button>
        </Form>
      </div>
      <Table
        loading={isFetching}
        rowKey="id"
        columns={columns}
        dataSource={filteredItems}
        pagination={{
          defaultPageSize: 50,
          pageSizeOptions: ['50'],
          showSizeChanger: true,
        }}
      />
      <FormDrawer
        open={drawerOpen}
        onClose={onClose}
        banner={selectedItem}
        type={formik.values.type}
        brands={uniqueBrands}
        groups={uniqueGroups}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  )
}

export default GroupBrand
