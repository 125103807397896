import React from 'react'
import styled from 'styled-components'
import { Button, Icon } from 'antd'

const Navigation = styled(
  ({
    handleNavigation,
    disabled,
    ...rest
  }: {
    disabled: boolean
    handleNavigation: any
    // className: string;
  }) => {
    return (
      <div style={{ marginTop: 20 }} {...rest}>
        <Button
          tabIndex={7}
          type="default"
          onClick={handleNavigation('previous')}
          disabled={disabled}
        >
          <Icon type="arrow-left" />
          Previous
        </Button>
        <Button
          tabIndex={8}
          type="default"
          onClick={handleNavigation('next')}
          disabled={disabled}
        >
          Next
          <Icon type="arrow-right" />
        </Button>
      </div>
    )
  },
)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

export default Navigation
