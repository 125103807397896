import api from 'api'
import { mapKeys } from 'lodash'
import { useQuery } from 'react-query'

const useRetailers = () => {
  return useQuery(
    'retailers',
    async () => {
      const data = await api.retailers.get()
      return mapKeys(data, 'Id') as any
    },
    {
      staleTime: Infinity,
      cacheTime: 1000 * 60 * 60,
      suspense: true,
    },
  )
}

export default useRetailers
