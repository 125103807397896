import React from 'react'
import { Select, Checkbox } from 'antd'
import api from 'api'
import RVSelect from 'components/RVSelect'
import useBannerTypes from 'hooks/useBannerTypes'
import useGroups from 'hooks/useGroups'
import { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import useCategories from 'hooks/useCategories'
import useBrands from 'hooks/useBrands'

const { Option } = Select

type Props = { formik: any; countries: any[] }

const InsertedFileds = ({ formik, countries }: Props) => {
  const { data: bannerTypes } = useBannerTypes()
  const { data: groups } = useGroups(formik.values.country)
  const { data: categories } = useCategories(formik.values.country)
  const { data: brands } = useBrands(formik.values.country)

  const [availableBrands, setAvailableBrands] = useState<any[]>([])

  const _countries: any = useMemo(
    () => _.mapKeys(countries, 'value'),
    [countries],
  )

  const _bannerTypes = useMemo(
    () =>
      bannerTypes.map((bt: any) => ({
        ...bt,
        name: `${bt.name} (${_countries[Number(bt.country)].name})`,
      })),
    [bannerTypes, _countries],
  )

  const availableGroups = useMemo(
    () =>
      groups.filter(
        (g: any) => Number(g.country) === Number(formik.values.country),
      ),
    [groups, formik.values.country],
  )

  useEffect(() => {
    const _groups: any = _.mapKeys(groups, 'value')
    setAvailableBrands(
      brands.map((b: any) => ({
        ...b,
        id: `${b.group_id}-${b.value}`,
        name: `${b.name} (${
          b.group_id && _groups[b.group_id] ? _groups[b.group_id].name : 'N/A'
        })`,
      })),
    )
  }, [brands, groups])

  const countryCategories = useMemo(
    () =>
      categories.filter((c: any) => {
        return Number(c.country) === Number(formik.values.country)
      }),
    [categories, formik.values.country],
  )

  return (
    <>
      <label htmlFor="group">Groups :</label>
      <RVSelect
        {...formik.getFieldProps('groups')}
        style={{ width: 300, height: '40px', marginRight: '15px' }}
        placeholder="Select Groups"
        onChange={(value: string[]) => formik.setFieldValue('groups', value)}
        options={availableGroups}
        multi={true}
      />
      <label htmlFor="brands">Brands :</label>
      <RVSelect
        {...formik.getFieldProps('brands')}
        style={{
          width: 300,
          height: '40px',
          marginRight: '15px',
        }}
        placeholder="Select Brands"
        onChange={(value: string[]) => formik.setFieldValue('brands', value)}
        options={availableBrands}
        multi={true}
      />{' '}
      <Checkbox
        checked={formik.values.excludeBrands}
        onChange={(e) =>
          formik.setFieldValue('excludeBrands', e.target.checked)
        }
      >
        Exclude ?
      </Checkbox>
      <label htmlFor="format">Format :</label>
      <Select
        {...formik.getFieldProps('format')}
        onBlur={() => void 1}
        id="format"
        size="large"
        placeholder="Select format"
        style={{ width: 300, marginRight: '15px' }}
        onChange={(value: string) => formik.setFieldValue('format', value)}
      >
        <Select.Option key={'0'} value={'0'}>
          All
        </Select.Option>
        {_bannerTypes.map((f: any) => (
          <Select.Option key={f.id} value={f.id}>
            {f.name}
          </Select.Option>
        ))}
      </Select>
      <label htmlFor="category">Categories :</label>
      <RVSelect
        style={{ width: 300, height: '40px', marginRight: '15px' }}
        options={countryCategories}
        labelKey={'name'}
        valueKey={'id'}
        multi={true}
        placeholder="Select categories"
        onChange={(value: string[]) =>
          formik.setFieldValue('categories', value)
        }
        value={formik.values.categories}
      />
      <Checkbox
        checked={formik.values.excludeCategories}
        onChange={(e) =>
          formik.setFieldValue('excludeCategories', e.target.checked)
        }
      >
        Exclude ?
      </Checkbox>
      <label htmlFor="is-promotion">Promotion ?</label>
      <Select
        {...formik.getFieldProps('is_promotion')}
        onBlur={() => void 1}
        id="is-promotion"
        style={{ width: 300 }}
        onChange={(value: number) =>
          formik.setFieldValue('is_promotion', value)
        }
        size="large"
      >
        <Option value={0}>All</Option>
        <Option value={2}>True</Option>
        <Option value={1}>False</Option>
      </Select>
      <label htmlFor="is-game">Game ?</label>
      <Select
        {...formik.getFieldProps('is_game')}
        onBlur={() => void 1}
        id="is-game"
        style={{ width: 300 }}
        onChange={(value: number) => formik.setFieldValue('is_game', value)}
        size="large"
      >
        <Option value={0}>All</Option>
        <Option value={2}>True</Option>
        <Option value={1}>False</Option>
      </Select>
      <label htmlFor="is-novelty">Novelty ?</label>
    </>
  )
}

export default InsertedFileds
