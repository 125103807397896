export enum BannerStatus {
  PENDING,
  APPROVED,
  CLEANED,
  INSERTED,
  PARTIALLY_INSERTED_BRANDS,
  READY_TO_BE_INSERTED,
  PARTIALLY_INSERTED_CATEGORIES,
}

export enum UserRoles {
  BANNER_USER,
  GRADING_USER,
  ADMIN,
}
