import React from 'react'
import { Icon } from 'antd'

const LoadingIndicator = () => {
  return (
    <Icon
      type="loading"
      style={{
        fontSize: 128,
        display: 'block',
        textAlign: 'center',
        marginTop: '45px',
      }}
      spin
    />
  )
}

export default LoadingIndicator
