import api from 'api'
import { useQuery } from 'react-query'

const useCountries = () => {
  return useQuery('countries', api.countries.get, {
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 60,
    suspense: true,
  })
}

export default useCountries
