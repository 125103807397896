import React from 'react'
import {
  Select,
  Button,
  Icon,
  Divider
} from 'antd'
import { map } from 'lodash'

const { Option } = Select

type Props = { formik: any, availableRetailers: any[] }

const RetailersField = ({ formik, availableRetailers }: Props) => {

  const selectAllRetailers = () => {
    formik.setFieldValue(
      'retailers',
      map(availableRetailers, (r: any) => r.Id),
    )
  }

  const unselectAllRetailers = () => {
    formik.setFieldValue('retailers', [])
  }

  return (
    <>
      <label htmlFor="retailers">Retailers :</label>
      <Select
        showSearch
        id="retailers"
        style={{ maxWidth: 300, width: 300 }}
        placeholder="Select retailers"
        optionFilterProp="children"
        size="large"
        mode="multiple"
        maxTagCount={2}
        maxTagPlaceholder={`${formik.values.retailers.length - 2} more`}
        showArrow={true}
        {...formik.getFieldProps('retailers')}
        onBlur={() => void 1}
        onChange={(value: number[]) =>
          formik.setFieldValue('retailers', value)
        }
        dropdownRender={(menu) => (
          <div>
            <div
              style={{
                padding: '4px 8px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="link"
                size="small"
                onMouseDown={(e) => e.preventDefault()}
                onClick={selectAllRetailers}
              >
                <Icon type="plus" /> All Retailers
              </Button>
              <Button
                type="link"
                size="small"
                onMouseDown={(e) => e.preventDefault()}
                onClick={unselectAllRetailers}
              >
                <Icon type="close" /> Unselect all
              </Button>
            </div>
            <Divider style={{ margin: '4px 0' }} />
            {menu}
          </div>
        )}
      >
        {map(availableRetailers, (retailer: any) => (
          <Option value={retailer.Id} key={retailer.Id}>
            {retailer.Name}
          </Option>
        ))}
      </Select>
    </>
  )
}

export default RetailersField
