import { Container } from 'components'
import styled from 'styled-components'

const SBContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 0 80px 0;

  .ant-select,
  .Select,
  .ant-calendar-picker,
  .ant-btn {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .ant-select-selection--multiple,
  .Select {
    cursor: pointer;
  }

  .Select > .Select-control:hover {
    border-color: #3e93de;
    border-right-width: 1px !important;
  }

  .Select > .Select-control {
    transition: all 0.3s;
  }
`

export default SBContainer
