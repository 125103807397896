import React, { useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import { useFormik } from 'formik'
import { Table, Button, Drawer, Form, Tag, Input } from 'antd'
import RVSelect from 'components/RVSelect'
import api from 'api'
import { matchSorter } from 'match-sorter'
import useCountries from 'hooks/useCountries'
import useBannerTypes from 'hooks/useBannerTypes'
import { useQueryClient } from 'react-query'

type FormValues = {
  name: string
  country: number
}

const initialValues = {
  name: '',
  country: 250,
}

const FormDrawer: React.FC<{
  open: boolean
  onClose: () => void
  onSubmit: (data: FormValues) => Promise<any>
  item: any
  countries: any[]
}> = ({ open, onClose, onSubmit, item, countries }) => {
  const formik = useFormik({
    initialValues: item
      ? { name: item.name, country: item.country }
      : initialValues,
    enableReinitialize: true,
    onSubmit: async (values, f) => {
      await onSubmit(values)
      f.resetForm()
    },
  })

  return (
    <Drawer
      title="Add Banner Type"
      visible={open}
      onClose={onClose}
      width={920}
    >
      <Form
        labelCol={{ xs: { span: 22 }, sm: { span: 6 } }}
        wrapperCol={{ xs: { span: 26 }, sm: { span: 18 } }}
      >
        <Form.Item label="Name">
          <Input
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Country">
          <RVSelect
            options={countries}
            labelKey={'name'}
            valueKey={'value'}
            onChange={(value) => {
              formik.setFieldValue('country', value)
            }}
            value={formik.values.country}
          />
          {formik.touched.country && formik.errors.country && (
            <span style={{ color: 'red' }}>{formik.errors.country}</span>
          )}
        </Form.Item>
        <Button
          style={{ float: 'right' }}
          size="large"
          type="primary"
          icon="save"
          loading={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
          disabled={!formik.dirty}
          htmlType="submit"
        >
          Save
        </Button>
      </Form>
    </Drawer>
  )
}

const BannerTypes = () => {
  const queryClient = useQueryClient()
  const { data: countries } = useCountries()
  const { data: bannerTypes } = useBannerTypes()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredItems, setFilteredItems] = useState<any[]>(bannerTypes)

  const _countries: any = useMemo(
    () => _.mapKeys(countries, 'value'),
    [countries],
  )

  useEffect(() => {
    setFilteredItems(
      matchSorter(bannerTypes, searchTerm, {
        keys: ['name'],
      }),
    )
  }, [bannerTypes, searchTerm])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (text: any, r: any, i: number) =>
        _countries[Number(text)] && _countries[Number(text)].name,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      render: (text: any, r: any, i: number) => (
        <Button onClick={() => handleEdit(r)}>Edit</Button>
      ),
    },
  ]

  const handleEdit = (item: any) => {
    setSelectedItem(item)
    setDrawerOpen(true)
  }

  const handleAdd = () => {
    setSelectedItem(null)
    setDrawerOpen(true)
  }

  const handleSubmit = async (data: FormValues) => {
    if (selectedItem) {
      const res = await api.bannerTypes.update(selectedItem.id, data)
      const _items = bannerTypes.filter((i: any) => selectedItem.id !== i.id)
      queryClient.setQueryData(['bannerTypes'], () => ([res, ..._items]))
    } else {
      const res = await api.bannerTypes.insert(data)
      queryClient.setQueryData(['bannerTypes'], () => ([res, ...bannerTypes]))
    }

    setSelectedItem(null)
    setDrawerOpen(false)
  }
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '15px',
        }}
      >
        <Input
          addonBefore="Filter"
          placeholder="Enter filter here..."
          size="large"
          style={{ marginRight: '15px' }}
          onChange={(e) => {
            setSearchTerm(e.target.value)
          }}
        />
        <Button type="primary" size="large" icon="plus" onClick={handleAdd}>
          Add
        </Button>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={filteredItems}
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ['50', '100', '500'],
          showSizeChanger: true,
        }}
      />
      <FormDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onSubmit={handleSubmit}
        item={selectedItem}
        countries={countries}
      />
    </div>
  )
}

export default BannerTypes
