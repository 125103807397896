import React from 'react'
import {
  Route,
  Redirect,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom'

interface IAuthenticatedRoute extends RouteProps {
  component: React.ComponentType<RouteComponentProps>
}

const AuthenticatedRoute: React.FC<IAuthenticatedRoute> = ({
  component: Component,
  path,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('accessToken') ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
)

export default AuthenticatedRoute
