import React, { useEffect, useMemo, useState } from 'react'
import _, { map } from 'lodash'
import { useFormik } from 'formik'
import {
  Table,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  Checkbox,
  notification,
  Tooltip,
} from 'antd'
import RVSelect from 'components/RVSelect'
import { matchSorter } from 'match-sorter'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import api from 'api'
import useCountries from 'hooks/useCountries'
import useCategories from 'hooks/useCategories'
import { TableRowSelection } from 'antd/lib/table'

const { Option } = Select

type FormValues = {
  cats_di: string[]
  cat_banners: string[]
}

const initialValues = {
  cats_di: [],
  cat_banners: null,
}

const FormDrawer: React.FC<{
  open: boolean
  onClose: () => void
  onSubmit: (data: FormValues) => Promise<any>
  items: any[]
  categories: any[]
  diCategories: any[]
  isLoadingUpdate: boolean
}> = ({
  open,
  onClose,
  onSubmit,
  categories,
  diCategories,
  items,
  isLoadingUpdate,
}) => {
  const formValues = items.reduce(
    (acc, curr) => {
      acc['cats_di'].push(curr.value)
      acc['cat_banners'] = curr.cat_banners
      return acc
    },
    { cats_di: [], cat_banners: null },
  )
  const formik = useFormik({
    initialValues: formValues.cats_di.length ? formValues : initialValues,
    enableReinitialize: true,
    onSubmit: async (values, f) => {
      await onSubmit(values)
      f.resetForm()
    },
  })

  return (
    <Drawer
      title="Map DI cats to banner cat"
      visible={open}
      onClose={onClose}
      width={920}
    >
      <Form
        labelCol={{ xs: { span: 22 }, sm: { span: 6 } }}
        wrapperCol={{ xs: { span: 26 }, sm: { span: 18 } }}
      >
        <Form.Item label="DI categories">
          <RVSelect
            options={diCategories}
            labelKey={'name'}
            valueKey={'value'}
            multi={true}
            onChange={(value) => formik.setFieldValue('cats_di', value)}
            value={formik.values.cats_di}
          />
        </Form.Item>
        <Form.Item label="Banner Categories">
          <RVSelect
            options={categories}
            labelKey={'name'}
            valueKey={'id'}
            onChange={(value) => formik.setFieldValue('cat_banners', value)}
            value={formik.values.cat_banners}
          />
        </Form.Item>
        <Button
          style={{ float: 'right' }}
          size="large"
          type="primary"
          icon="save"
          loading={isLoadingUpdate}
          onClick={() => formik.handleSubmit()}
          disabled={!formik.dirty}
          htmlType="submit"
        >
          Save
        </Button>
      </Form>
    </Drawer>
  )
}

const extractTextWithinParentheses = (item: any) => {
  const [_, str] = (item.name || '').split(item.cat_di)
  const matches = (str || '').match(/^\s*\((.*)\)\s*$/)
  return matches ? matches[1] : ''
}
const searchInitialValues = {
  country: '',
  newCatDI: false,
}
const pageSize = 50
const GroupSettings = () => {
  const formik = useFormik({
    initialValues: searchInitialValues,
    onSubmit: async () => {
      await refetch()
    },
  })
  const { data: countries = [] } = useCountries()
  const { data: categories = [] } = useCategories(+formik.values.country)
  const {
    isFetching,
    data: diCategories,
    refetch,
  } = useQuery(
    'di-categories',
    () => api.categoriesMapping.get(formik.values),
    { enabled: false },
  )

  const { isLoading: isLoadingUpdate, mutateAsync: updateDiCategory } =
    useMutation((formData) => api.categoriesMapping.update(formData))
  const categoriesHM: any = useMemo(
    () => _.mapKeys(categories, 'id'),
    [categories],
  )
  const diCategoriesHM: any = useMemo(
    () => _.mapKeys(diCategories, 'value'),
    [diCategories],
  )
  const [searchTerm, setSearchTerm] = useState('')
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [unmatched, setUnmatched] = useState(true)
  const [selectedItems, setSelectedItems] = useState<any[]>([])
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const queryClient = useQueryClient()

  const selectedRowIds = useMemo(
    () => selectedItems.map((item) => item.value),
    [selectedItems],
  )
  useEffect(() => {
    const sorted = matchSorter(diCategories ?? [], '', {
      keys: [(item: any) => extractTextWithinParentheses(item)],
    }).map((item) => ({
      ...item,
      category: categoriesHM?.[item?.cat_banners]?.name ?? '',
    }))
    let filtered = searchTerm
      ? matchSorter(sorted, searchTerm.trim(), {
          keys: ['value', 'name', 'category'],
          threshold: matchSorter.rankings.CONTAINS,
        })
      : sorted
    if (unmatched) {
      filtered = filtered.filter((item) =>
        [undefined, null, ''].includes(item.category),
      )
    }
    setFilteredItems(filtered)
  }, [categoriesHM, diCategories, searchTerm, unmatched])

  const columns = [
    {
      title: `DI category (${filteredItems.length})`,
      dataIndex: 'value',
      key: 'value',
      width: 180,
    },
    {
      title: 'DI category name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            gap: 10,
            alignItems: 'center',
            minWidth: 300,
          }}
        >
          <span>Banner category</span>
          <Tooltip title="Only unmatched DI categories">
            <Checkbox
              checked={unmatched}
              onChange={() => setUnmatched((unmatched) => !unmatched)}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      width: 90,
      render: (text: any, r: any, i: number) => (
        <Button
          disabled={selectedItems.length > 1}
          onClick={() => handleEdit(r)}
        >
          Edit
        </Button>
      ),
    },
  ]

  const handleEdit = (item: any) => {
    if (item) {
      setSelectedItems([item])
    }
    setDrawerOpen(true)
  }
  const handleSubmit = async (values: any) => {
    try {
      const cats_di = (values?.cats_di || []).map((id: string) => ({
        id,
        name: diCategoriesHM[id].name,
      }))
      await updateDiCategory({ ...values, cats_di })
      onClose()
      queryClient.setQueryData(
        'di-categories',
        (diCategories || []).filter(
          (di: any) => !selectedRowIds.includes(di.value),
        ),
      )
      setSelectedItems([])
      notification['success']({
        message: `DI category updated successfully`,
      })
    } catch (err: any) {
      notification['error']({
        message: err.message,
      })
    }
  }

  const onClose = () => {
    setDrawerOpen(false)
    setSelectedItems([])
  }

  const handleSelect = (record: any, selected: any) => {
    if (selected) {
      setSelectedItems((items: any[]) => [...items, record])
    } else {
      setSelectedItems((items: any[]) => {
        return items.filter((item) => item.value !== record.value)
      })
    }
  }

  const toggleSelectAll = () => {
    setSelectedItems((items: any[]) => {
      if (items.length === filteredItems.length) {
        return []
      } else if (!items.length) {
        const pageItemsSelected = filteredItems.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize,
        )
        return pageItemsSelected
      } else {
        return filteredItems
      }
    })
  }
  const headerCheckbox = (
    <Checkbox
      checked={!!selectedItems.length}
      indeterminate={
        selectedRowIds.length > 0 &&
        selectedRowIds.length < filteredItems.length
      }
      onChange={toggleSelectAll}
    />
  )

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedRowIds,
    type: 'checkbox',
    fixed: true,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,
    getCheckboxProps: (record: any) => {
      return {
        checked: selectedRowIds.includes(record.value),
        name: record.name,
      }
    },
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '15px',
          gap: '40px',
        }}
      >
        <Input
          addonBefore="Filter"
          placeholder="Enter filter here..."
          size="large"
          onChange={(e) => {
            setSearchTerm(e.target.value)
          }}
        />
        <Button
          icon="close"
          size="large"
          disabled={selectedItems.length <= 0 || isLoadingUpdate}
          onClick={async () => {
            const values: any = {
              cats_di: selectedRowIds.map((id: string) => ({
                id,
                name: diCategoriesHM[id].name,
              })),
              cat_banners: 'na',
            }
            try {
              await updateDiCategory(values)
              queryClient.setQueryData(
                'di-categories',
                (diCategories || []).filter(
                  (di: any) => !selectedRowIds.includes(di.value),
                ),
              )
              setSelectedItems([])
              notification['success']({
                message: `DI category updated successfully`,
              })
            } catch (err: any) {
              notification['error']({
                message: err.message,
              })
            }
          }}
        >
          unmatchable
        </Button>
        <Button
          icon="edit"
          size="large"
          disabled={selectedItems.length <= 1}
          onClick={() => {
            setDrawerOpen(true)
          }}
        >
          Edit
        </Button>

        <Form
          onSubmit={formik.handleSubmit}
          style={{
            display: 'flex',
            gap: '15px',
          }}
        >
          <Select
            showSearch
            id="country"
            style={{ maxWidth: 300, width: 300 }}
            placeholder="Select country"
            optionFilterProp="children"
            size="large"
            onBlur={() => void 1}
            onChange={(value) => {
              formik.setFieldValue('country', value)
            }}
          >
            {map(countries, (country: any) => (
              <Option value={country.value} key={country.value}>
                {country.name}
              </Option>
            ))}
          </Select>
          <Checkbox
            name="newCatDI"
            onChange={(event) =>
              formik.setFieldValue('newCatDI', event.target.checked)
            }
            style={{ alignSelf: 'center', width: 120 }}
          >
            new cat DI
          </Checkbox>
          <Button
            type="primary"
            size="large"
            icon="search"
            htmlType="submit"
            loading={isFetching}
          >
            Search
          </Button>
        </Form>
      </div>
      <Table
        rowKey="cats_di"
        loading={isFetching}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={filteredItems}
        pagination={{
          defaultPageSize: pageSize,
          pageSizeOptions: [`${pageSize}`],
          showSizeChanger: true,
          onChange: (pagination) => {
            setCurrentPage(pagination)
          },
        }}
      />
      <FormDrawer
        open={drawerOpen}
        onClose={onClose}
        onSubmit={handleSubmit}
        categories={categories}
        diCategories={diCategories}
        items={selectedItems}
        isLoadingUpdate={isLoadingUpdate}
      />
    </div>
  )
}

export default GroupSettings
