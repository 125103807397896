import React, { useState, useEffect } from 'react'
import api from 'api'
import { Container } from 'components'
import { User } from 'types'
import {
  Layout as ALayout,
  Table,
  Icon,
  Switch,
  notification,
  Button,
  Form,
  Drawer,
  Input,
  Tag,
} from 'antd'
import { Helmet } from 'react-helmet'
import RVSelect from 'components/RVSelect'
import { useFormik } from 'formik'
import { UserRoles } from 'consts'
import _ from 'lodash'

const { Column } = Table

const roles = Object.entries(UserRoles)
  .filter((e) => !isNaN(e[0] as any))
  .map((e) => ({ name: e[1], id: Number(e[0]) }))

type FormValues = {
  roles: number[]
  mail: string
}

const FormDrawer: React.FC<{
  open: boolean
  onClose: () => void
  onSubmit: (data: FormValues) => Promise<any>
  roles: any[]
  item: any
}> = ({ open, onClose, onSubmit, roles, item }) => {
  const formik = useFormik({
    initialValues: item
      ? { roles: item.roles, mail: item.mail }
      : { roles: [], mail: '' },
    enableReinitialize: true,
    onSubmit: async (values, f) => {
      await onSubmit(values)
      f.resetForm()
    },
  })

  return (
    <Drawer title="Edit User" visible={open} onClose={onClose} width={920}>
      <Form
        labelCol={{ xs: { span: 22 }, sm: { span: 6 } }}
        wrapperCol={{ xs: { span: 26 }, sm: { span: 18 } }}
      >
        <Form.Item label="Email">
          <Input defaultValue={formik.values.mail} disabled={true} />
        </Form.Item>
        <Form.Item label="Roles">
          <RVSelect
            options={roles}
            labelKey={'name'}
            valueKey={'id'}
            multi
            onChange={(value) => formik.setFieldValue('roles', value)}
            value={formik.values.roles}
          />
          {formik.touched.roles && formik.errors.roles && (
            <span style={{ color: 'red' }}>{formik.errors.roles}</span>
          )}
        </Form.Item>
        <Button
          style={{ float: 'right' }}
          size="large"
          type="primary"
          icon="save"
          loading={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
          disabled={!formik.dirty}
          htmlType="submit"
        >
          Save
        </Button>
      </Form>
    </Drawer>
  )
}

const Users: React.FC = () => {
  const [users, setUsers] = useState<any[]>([])
  const [loading, setIsLoading] = useState(false)

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)

  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = async () => {
    setIsLoading(true)

    const res = await api.user.get()
    setUsers(res)

    setIsLoading(false)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'E-mail',
      dataIndex: 'mail',
      key: 'mail',
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'retailers',
      render: (text: any, r: any, i: number) => (
        <span>
          {text.map((r: any) => (
            <Tag key={r} color="blue">
              {roles[r].name}
            </Tag>
          ))}
        </span>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      render: (text: any, r: any, i: number) => (
        <Button onClick={() => handleEdit(r)}>Edit</Button>
      ),
    },
  ]

  const handleEdit = (item: any) => {
    setSelectedItem(item)
    setDrawerOpen(true)
  }

  const handleSubmit = async (data: FormValues) => {
    await api.user.update(selectedItem.id, data.roles)

    const _items = users.filter((i) => i.id !== selectedItem.id)
    setUsers([{ ...selectedItem, roles: data.roles }, ..._items])

    setSelectedItem(null)
    setDrawerOpen(false)
  }

  if (loading) {
    return (
      <Icon
        type="loading"
        style={{
          fontSize: 128,
          display: 'block',
          textAlign: 'center',
          marginTop: '45px',
        }}
        spin
      />
    )
  }

  return (
    <Container>
      <Helmet>
        <title>Users - Media management - Dataimpact</title>
      </Helmet>
      <Table dataSource={users} rowKey="id" columns={columns} />

      <FormDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onSubmit={handleSubmit}
        item={selectedItem}
        roles={_.values(roles)}
      />
    </Container>
  )
}

export default Users
