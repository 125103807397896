import { DatePicker } from 'antd'
import React from 'react'


const { RangePicker } = DatePicker

type Props = { formik: any }

const PeriodField = ({ formik }: Props) => {
  return (
    <>

      <label htmlFor="period">Period :</label>
      <RangePicker
        {...formik.getFieldProps('period')}
        style={{ maxWidth: 300, width: 300, marginRight: '15px' }}
        onChange={(value) => formik.setFieldValue('period', value)}
        size="large"
        id="period"
      />
    </>
  )
}

export default PeriodField
