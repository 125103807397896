const transformBrands = (brands: any[], groups: any) =>
  brands.map((b: any) => ({
    ...b,
    id: `${b.group_id ?? 0}-${b.value}`,
    name: `${b.name} (${
      b.group_id && groups[b.group_id] ? groups[b.group_id].name : 'N/A'
    })`,
    originalName: b.name,
  }))

export default transformBrands
