
import React from 'react'
import { Select } from 'antd'


type Props = { formik: any }

const { Option } = Select

const TypeField = ({ formik }: Props) => {
  return (
    <>

      <label htmlFor="type">Type :</label>
      <Select
        {...formik.getFieldProps('type')}
        onBlur={() => void 1}
        id="type"
        style={{ width: 300 }}
        onChange={(value: number) =>
          formik.setFieldValue('type', value)
        }
        size="large"
      >
        <Option value={0}>All</Option>
        <Option value={1}>Category</Option>
        <Option value={2}>Search</Option>
      </Select>
    </>
  )
}

export default TypeField
