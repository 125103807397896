import React, { CSSProperties, useEffect, useRef } from 'react'

import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'

// Then import the virtualized Select HOC
import Select from 'react-virtualized-select'

const RVSelect: React.FC<{
  options: any
  value: any
  multi?: boolean
  disabled?: boolean
  labelKey?: string
  valueKey?: string
  onChange: (categories: any) => void
  style?: CSSProperties
  placeholder?: string
  customOptionHeight?: number
  tabIndex?: number
  autoFocus?: boolean
}> = ({
  options,
  value,
  onChange,
  multi = false,
  disabled = false,
  labelKey = 'name',
  valueKey = 'value',
  style,
  placeholder,
  customOptionHeight,
  tabIndex = 0,
  autoFocus = false,
}) => {
  const selectRef = useRef<any>(null)

  useEffect(() => {
    if (selectRef.current && autoFocus) {
      selectRef.current.focus()
    }
  }, [autoFocus])

  const handleChange = (v: any) => {
    onChange(
      v === null
        ? null
        : multi
        ? v.map((_v: any) => _v[valueKey])
        : v[valueKey],
    )
  }

  return (
    <Select
      ref={selectRef}
      tabIndex={tabIndex}
      style={style}
      placeholder={placeholder}
      options={options}
      labelKey={labelKey}
      valueKey={valueKey}
      multi={multi}
      disabled={disabled}
      value={value}
      onChange={handleChange}
      optionHeight={customOptionHeight}
    />
  )
}

export default RVSelect
