import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { chunk, range } from 'lodash'
import { Button } from 'antd'
import cx from 'classnames'

const PeriodsTable = styled.table`
  width: 360px;
  border: 1px solid #eee;
  /* height: 300px; */
  td {
    padding: 5px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    &:hover {
      background-color: white;
      color: #ddd;
    }
    &:nth-child(odd) {
      background-color: #f5f5f5;
    }
    &.disabled {
      cursor: not-allowed;
      color: #c0c0c0;
    }
    &.selected {
      background: #64a944;
      color: white;
    }
    &.inRange {
      background-color: #f4c115;
    }
  }
`

const Header = styled.div`
  width: 360px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
  span {
    font-weight: 600;
  }
`

const maxYear = moment().year()
const minYear = 2014
const currentWeek = moment().week()

// const lastMonth = moment().subtract(4, "week");
// const lastMonthPeriod = [lastMonth.year(), lastMonth.week()];

const PeriodPicker: React.FC<{ onChange: (p: string) => void }> = ({
  onChange,
}) => {
  const [year, setYear] = useState(maxYear)
  const [selectedPeriod, setSelectedPeriod] = useState(0)

  const periods: number[] = range(1, 13)

  const handlePeriodClick = (p: number, isDisabled: boolean) => {
    setSelectedPeriod(p)
    onChange(`${year}${p <= 9 ? 0 : ''}${p}`)
  }

  return (
    <>
      <Header>
        <Button
          icon="left"
          size="small"
          onClick={() => setYear(year - 1)}
          disabled={year === minYear}
        />
        <span>{year}</span>
        <Button
          icon="right"
          size="small"
          onClick={() => setYear(year + 1)}
          disabled={year === maxYear}
        />
      </Header>
      <PeriodsTable>
        <tbody>
          {chunk(periods, 13).map((c, i) => (
            <tr key={i}>
              {c.map((p) => {
                const isDisabled = year === maxYear && currentWeek < p
                return (
                  <td
                    key={p}
                    onClick={() => handlePeriodClick(p, isDisabled)}
                    className={cx({
                      disabled: isDisabled,
                      selected: p === selectedPeriod,
                    })}
                  >
                    {p}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </PeriodsTable>
    </>
  )
}

export default PeriodPicker
