import React, { useEffect, useMemo, useState } from 'react'
import { Button, Table } from 'antd'
import moment from 'moment'
import api from 'api'
import _ from 'lodash'
import useRetailers from 'hooks/useRetailers'

const { Column } = Table

const BannerDetailsTable: React.FC<{ banner: any }> = ({ banner }) => {
  const [bannerDetails, setBannerDetails] = useState<any[]>([])
  const [detailsLoading, setDetailsLoading] = useState(false)
  const { data: retailers } = useRetailers()

  const retailersHT = useMemo(() => _.mapKeys(retailers, 'Id'), [retailers])

  useEffect(() => {
    const loadBannerDetails = async () => {
      setDetailsLoading(true)
      const result = await api.banners.details(banner)
      setDetailsLoading(false)
      setBannerDetails(result)
    }
    if (banner) {
      loadBannerDetails()
    }
  }, [banner])

  return (
    <Table
      dataSource={bannerDetails}
      size={'small'}
      loading={detailsLoading}
      rowKey={(record, index) => `${index}`}
    >
      <Column
        title="Retailer"
        dataIndex="retailer_id"
        key="retailer_id"
        render={(retailer: any) => retailersHT[Number(retailer)].Name}
      />
      <Column
        title="Category"
        dataIndex="category"
        key="category"
        render={(category: any[]) =>
          Array.isArray(category)
            ? category.join('->')
            : 'Cannot parse category'
        }
      />
      <Column
        title="Redirect URL"
        dataIndex="redirect_url"
        key="redirect_url"
        render={(url) => (
          <Button href={url} target="_blank">
            Go to
          </Button>
        )}
      />
      <Column
        title="Screenshot"
        dataIndex="screenshot_image_hash"
        key="screenshot_image"
        render={(url) =>
          url ? (
            <a
              href={`//storage.googleapis.com/di_banners_images/images/${url}.png`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`//storage.googleapis.com/di_banners_images/images/${url}.png`}
                style={{ maxWidth: '350px', height: 'auto' }}
              />
            </a>
          ) : (
            'N/A'
          )
        }
      />
      <Column
        title="Crawl date"
        dataIndex="crawl_date"
        key="crawl_date"
        render={(date) => {
          const timestamp = new Date(date)
          return <p>{moment(timestamp).format('DD/MM/YYYY')}</p>
        }}
      />
      <Column
        title="Search term"
        dataIndex="search_term"
        key="search_term"
        render={(searchTerm: any[]) => <p>{searchTerm ? searchTerm : 'N/A'}</p>}
      />
    </Table>
  )
}

export default BannerDetailsTable
