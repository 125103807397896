import React from 'react'
import { Container } from 'components'
import { Tabs } from 'antd'
import GroupSettings from 'components/Banners/Admin/GroupSettings'
import CategoryMapper from 'components/Banners/Admin/CategoryMapper'
import GroupBrand from 'components/Banners/Admin/GroupBrand'
import Categories from 'components/Banners/Admin/Categories'
import BannerTypes from 'components/Banners/Admin/BannerTypes'
import Cache from 'components/Banners/Admin/Cache'

const Admin: React.FC = () => {
  return (
    <Container>
      <Tabs>
        <Tabs.TabPane tab="Assign Retailers to Groups" key="groups">
          <GroupSettings />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Mapping new categories DI" key="mapper">
          <CategoryMapper />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Brand Group" key="brand-group">
          <GroupBrand />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Categories Management" key="categories">
          <Categories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Banner Types Management" key="bannertypes">
          <BannerTypes />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Cache Management" key="cache">
          <Cache />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  )
}

export default Admin
