import React, { useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import { useFormik } from 'formik'
import { Table, Button, Drawer, Form, Input } from 'antd'
import RVSelect from 'components/RVSelect'
import api from 'api'
import useDICategories from 'hooks/useDICategories'
import useCategories from 'hooks/useCategories'
import useCountries from 'hooks/useCountries'
import { useQueryClient } from 'react-query'

type FormValues = {
  id: string
  name: string
  country: string
  di_cats: string[] | null
}

const initialValues = {
  id: '',
  name: '',
  country: 'FR',
  di_cats: null,
}

const FormDrawer: React.FC<{
  open: boolean
  onClose: () => void
  onSubmit: (data: FormValues) => Promise<any>
  item: any
}> = ({ open, onClose, onSubmit, item }) => {
  const formik = useFormik({
    initialValues: item
      ? {
        id: item.id,
        name: item.name,
        country: item.country,
        di_cats: item.di_cats,
      }
      : initialValues,
    enableReinitialize: true,
    onSubmit: async (values, f) => {
      await onSubmit(values)
      f.resetForm()
    },
  })

  const { data: countries } = useCountries()
  const { data: diCategories } = useDICategories(formik.values.country)

  const diCats = useMemo(
    () => (diCategories || []).map((c) => ({ ...c, label: c.name })),
    [diCategories],
  )

  return (
    <Drawer
      title="Add Group Access"
      visible={open}
      onClose={onClose}
      width={920}
    >
      <Form
        labelCol={{ xs: { span: 22 }, sm: { span: 6 } }}
        wrapperCol={{ xs: { span: 26 }, sm: { span: 18 } }}
      >
        <Form.Item label="Name">
          <Input
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
          />
        </Form.Item>
        <Form.Item label="country">
          <RVSelect
            style={{ width: '100%' }}
            options={countries.map((c: any) => ({ ...c, label: c.name }))}
            value={formik.values.country}
            onChange={(e) => formik.setFieldValue('country', e)}
          />
        </Form.Item>
        <Form.Item label="DI Categories">
          <RVSelect
            style={{ width: '100%' }}
            options={diCats}
            value={formik.values.di_cats}
            onChange={(e) => {
              formik.setFieldValue('di_cats', e)
            }}
            multi
            disabled={!formik.values.country}
            customOptionHeight={80}
          />
        </Form.Item>
        <Button
          style={{ float: 'right' }}
          size="large"
          type="primary"
          icon="save"
          loading={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
          disabled={!formik.dirty}
          htmlType="submit"
        >
          Save
        </Button>
      </Form>
    </Drawer>
  )
}

const Categories = () => {
  const queryClient = useQueryClient()
  const { data: categories } = useCategories()
  const { data: countries } = useCountries()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState('')

  const _countries = useMemo(() => _.mapKeys(countries, 'value'), [countries])
  const _categories = useMemo(
    () =>
      categories.map((c: any) => ({
        id: c.id,
        name: c.name,
        country: _countries[c.country] ? _countries[c.country].value : '',
        di_cats: c.di_cats,
      })),
    [categories, _countries],
  )
  const [filteredCategories, setFilteredCategories] =
    useState<any[]>(_categories)

  useEffect(() => {
    setFilteredCategories(
      _categories.filter((c: any) => {
        return (
          c.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (!!_countries[c.country] &&
            _countries[c.country].name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()))
        )
      }),
    )
  }, [_categories, searchTerm, _countries])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (text: any, r: any, i: number) => (
        <span>{_countries[text] ? _countries[text].name : ''}</span>
      ),
      sorter: (a: any, b: any) =>
        String(a.country)
          .toLowerCase()
          .localeCompare(String(b.country).toLowerCase()),
    },
    {
      title: 'DI Categories',
      dataIndex: 'di_cats',
      key: 'di_cats',
      render: (text: any, r: any, i: number) => (
        <span>{(r.di_cats || []).join(', ')}</span>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      render: (text: any, r: any, i: number) => (
        <Button onClick={() => handleEdit(r)}>Edit</Button>
      ),
    },
  ]

  const handleEdit = (item: any) => {
    setSelectedItem(item)
    setDrawerOpen(true)
  }

  const handleAdd = () => {
    setSelectedItem(null)
    setDrawerOpen(true)
  }

  const handleSubmit = async (data: FormValues) => {
    if (selectedItem) {
      const res = await api.categories.update(selectedItem.id, {
        name: data.name,
        country: data.country,
        di_cats: data.di_cats,
      })
      const _items = categories.filter((i: any) => selectedItem.id !== i.id)
      queryClient.setQueryData(['categories', null], () => ([res, ..._items]))
    } else {
      const res = await api.categories.insert({
        name: data.name,
        country: data.country,
        di_cats: data.di_cats,
      })
      queryClient.setQueryData(['categories', null], () => ([res, ...categories]))
    }

    setSelectedItem(null)
    setDrawerOpen(false)
  }
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '15px',
        }}
      >
        <Input
          addonBefore="Filter"
          placeholder="Enter filter here..."
          size="large"
          style={{ marginRight: '15px' }}
          onChange={(e) => {
            setSearchTerm(e.target.value)
          }}
        />
        <Button type="primary" size="large" icon="plus" onClick={handleAdd}>
          Add
        </Button>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={filteredCategories}
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ['50', '100', '500'],
          showSizeChanger: true,
        }}
      />
      <FormDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onSubmit={handleSubmit}
        item={selectedItem}
      />
    </div>
  )
}

export default Categories
