import React, { Suspense } from 'react'
import { Route } from 'react-router-dom'
import { HasAccessToComponent, Layout } from './components'
import { Users, Home } from './containers'
import Banners from 'containers/Banners'

import { UserRoles } from 'consts'
import NotAuthorized from 'containers/Banners/NotAuthorized'
import LoadingIndicator from 'components/LoadingIndicator'

const App: React.FC = () => {
  return (
    <Layout>
      <Route exact path="/" component={Home} />
      <Route exact path="/401" component={NotAuthorized} />

      <HasAccessToComponent
        exact
        path="/users"
        permittedRoles={[UserRoles.ADMIN]}
        component={Users}
      />

      <Suspense fallback={<LoadingIndicator />}>
        <Route path="/banners" component={Banners} />
      </Suspense>
    </Layout>
  )
}

export default App
