import React, { useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import { useFormik } from 'formik'
import { Table, Button, Drawer, Form, Tag, Input, Icon } from 'antd'
import RVSelect from 'components/RVSelect'
import api from 'api'
import { matchSorter } from 'match-sorter'
import useRetailers from 'hooks/useRetailers'
import useCategories from 'hooks/useCategories'
import useGroups from 'hooks/useGroups'

type FormValues = {
  group: string | undefined
  retailers: number[]
  categories: string[]
}

const initialValues = {
  group: undefined,
  categories: [],
  retailers: [],
}

const FormDrawer: React.FC<{
  open: boolean
  onClose: () => void
  onSubmit: (data: FormValues) => Promise<any>
  groups: any[]
  item: any
  categories: any[]
  retailers: any[]
}> = ({ open, onClose, onSubmit, groups, retailers, categories, item }) => {
  const formik = useFormik({
    initialValues: !!item ? item : initialValues,
    enableReinitialize: true,
    onSubmit: async (values, f) => {
      await onSubmit(values)
      f.resetForm()
    },
  })
  const groupsHM = useMemo(() => _.mapKeys(groups, 'value'), [groups])
  const countryCategories = useMemo(() => {
    if (!formik.values.group || !groupsHM[formik.values.group]) {
      return categories
    }

    return categories.filter((cat) => {
      return (
        Number(cat.country) === Number(groupsHM[formik.values.group].country)
      )
    })
  }, [groupsHM, categories, formik.values.group])

  return (
    <Drawer
      title="Add Group Access"
      visible={open}
      onClose={onClose}
      width={920}
    >
      <Form
        labelCol={{ xs: { span: 22 }, sm: { span: 6 } }}
        wrapperCol={{ xs: { span: 26 }, sm: { span: 18 } }}
      >
        <Form.Item label="Group">
          <RVSelect
            options={groups}
            disabled={!!item}
            labelKey={'name'}
            valueKey={'value'}
            onChange={(value) => formik.setFieldValue('group', value)}
            value={formik.values.group}
          />
          {formik.touched.group && formik.errors.group && (
            <span style={{ color: 'red' }}>{formik.errors.group}</span>
          )}
        </Form.Item>
        <Form.Item label="Categories">
          <RVSelect
            options={countryCategories}
            multi={true}
            labelKey={'name'}
            valueKey={'id'}
            onChange={(value) => formik.setFieldValue('categories', value)}
            value={formik.values.categories}
          />
          {formik.touched.categories && formik.errors.categories && (
            <span style={{ color: 'red' }}>{formik.errors.categories}</span>
          )}
        </Form.Item>
        <Form.Item label="Retailers">
          <RVSelect
            options={retailers}
            multi={true}
            labelKey={'Name'}
            valueKey={'Id'}
            onChange={(value) => formik.setFieldValue('retailers', value)}
            value={formik.values.retailers}
          />
          {formik.touched.categories && formik.errors.categories && (
            <span style={{ color: 'red' }}>{formik.errors.categories}</span>
          )}
        </Form.Item>
        <Button
          style={{ float: 'right' }}
          size="large"
          type="primary"
          icon="save"
          loading={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
          disabled={!formik.dirty}
          htmlType="submit"
        >
          Save
        </Button>
      </Form>
    </Drawer>
  )
}

const GroupSettings = () => {
  const { data: groups } = useGroups()
  const { data: categories } = useCategories()
  const { data: retailers } = useRetailers()
  const _retailers: any = useMemo(() => _.values(retailers), [retailers])
  const _groups: any = useMemo(() => _.mapKeys(groups, 'value'), [groups])
  const _categories: any = useMemo(
    () => _.mapKeys(categories, 'id'),
    [categories],
  )

  const [availableGroups, setAvailableGroups] = useState<any[]>([])
  const [items, setItems] = useState<any[]>([])
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [searchTearm, setSearchTerm] = useState('')
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  useEffect(() => {
    api.banner_groups_access.get().then((res) => {
      const items = res.map((i: any) => ({
        ...i,
        group_name: _groups[i.group] ? _groups[i.group].name : 'N/A',
      }))
      setItems(items)
    })
  }, [_groups])

  useEffect(() => {
    setFilteredItems(
      searchTearm
        ? matchSorter(items, searchTearm, {
            keys: ['group_name'],
          })
        : items,
    )
  }, [items, searchTearm])

  useEffect(() => {
    const insertedGroups = items.map((i) => i.group)
    setAvailableGroups(
      groups.filter((g: any) => !insertedGroups.includes(g.value)),
    )
  }, [groups, items])

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: (params: {
      setSelectedKeys: any
      selectedKeys: any
      confirm: any
      clearFilters: any
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={params.selectedKeys[0]}
          onChange={(e) =>
            params.setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(params.selectedKeys, params.confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() =>
            handleSearch(params.selectedKeys, params.confirm, dataIndex)
          }
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(params.clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) => {
      return record[dataIndex]
        .map((r: any) =>
          dataIndex == 'categories' ? _categories[r].name : retailers[r].Name,
        )
        .filter((c: any) =>
          c.toString().toLowerCase().includes(value.toLowerCase()),
        ).length
    },
  })

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: any) => {
    clearFilters()
    setSearchText('')
  }

  const columns = [
    {
      title: 'Group',
      dataIndex: 'group_name',
      key: 'group',
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      key: 'categories',
      render: (text: any) => (
        <span>
          {text.map((c: any) => (
            <Tag color="blue" key={c}>
              {_categories[c].name}
            </Tag>
          ))}
        </span>
      ),
      ...getColumnSearchProps('categories'),
    },
    {
      title: 'Retailers',
      dataIndex: 'retailers',
      key: 'retailers',
      render: (text: any, r: any, i: number) => (
        <span>
          {text.map((r: any) => (
            <Tag color="blue" key={r}>
              {retailers[r].Name}
            </Tag>
          ))}
        </span>
      ),
      ...getColumnSearchProps('retailers'),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      render: (text: any, r: any, i: number) => (
        <Button onClick={() => handleEdit(r)}>Edit</Button>
      ),
    },
  ]

  const handleEdit = (item: any) => {
    setSelectedItem(item)
    setDrawerOpen(true)
  }

  const handleAdd = () => {
    setSelectedItem(null)
    setDrawerOpen(true)
  }

  const handleSubmit = async (data: FormValues) => {
    if (selectedItem) {
      await api.banner_groups_access.update(selectedItem.id, {
        group: data.group,
        categories: data.categories,
        retailers: data.retailers,
      })
      const _items = items.filter((i) => selectedItem.id !== i.id)
      setItems([data, ..._items])
    } else {
      const res = await api.banner_groups_access.insert(data)
      setItems([...items, res])
    }

    setSelectedItem(null)
    setDrawerOpen(false)
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '15px',
        }}
      >
        <Input
          addonBefore="Filter"
          placeholder="Enter filter here..."
          size="large"
          style={{ marginRight: '15px' }}
          onChange={(e) => {
            setSearchTerm(e.target.value)
          }}
        />
        <Button type="primary" size="large" icon="plus" onClick={handleAdd}>
          Add
        </Button>
      </div>
      <Table
        rowKey="group"
        columns={columns}
        dataSource={filteredItems}
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ['50', '100', '500'],
          showSizeChanger: true,
        }}
      />

      <FormDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onSubmit={handleSubmit}
        groups={!!selectedItem ? groups : availableGroups}
        retailers={_retailers}
        categories={categories}
        item={selectedItem}
      />
    </div>
  )
}

export default GroupSettings
