import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { GoogleOAuthProvider } from '@react-oauth/google'
import App from './App'
import './index.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Login, AuthenticatedRoute } from 'components'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    },
  },
})
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string

ReactDOM.render(
  <GoogleOAuthProvider clientId={clientId}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <AuthenticatedRoute path="/" component={App} />
          <Route component={() => <div>404 - Not found !</div>} />
        </Switch>
      </Router>
    </QueryClientProvider>
  </GoogleOAuthProvider>,

  document.getElementById('root'),
)
