import React from 'react'
import {
  Redirect,
  RouteProps,
  RouteComponentProps,
  Route,
} from 'react-router-dom'
import hasAccessToRoute from 'utils/hasAccessToRoute'

interface IAuthenticatedRoute extends RouteProps {
  component: React.ComponentType<RouteComponentProps>
  permittedRoles: number[]
}

const HasRightAccessToComponent: React.FC<IAuthenticatedRoute> = ({
  component: Component,
  permittedRoles,
  ...rest
}) => {
  const userRoles: number[] = JSON.parse(localStorage.getItem('roles') as any)
  const hasAccess = hasAccessToRoute(userRoles, permittedRoles)

  return (
    <Route
      {...rest}
      render={(props) =>
        hasAccess ? <Component {...props} /> : <Redirect to="/401" />
      }
    />
  )
}

export default HasRightAccessToComponent
