import React, { useState, useEffect, Suspense } from 'react'
import { Alert, Button, Drawer } from 'antd'
import { filter, findIndex } from 'lodash'
import { RangePickerValue } from 'antd/lib/date-picker/interface'
import { Moment } from 'moment'
import { useCurrentRequest } from 'contexts/CurrentBannerRequest'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { BannerStatus } from 'consts'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import InsertedFileds from './SearchBar/InsertedFileds'
import CountryField from './SearchBar/CountryField'
import RetailersField from './SearchBar/RetailersField'
import StatusField from './SearchBar/StatusField'
import Container from './SearchBar/Container'
import TypeField from './SearchBar/TypeField'
import PeriodField from './SearchBar/PeriodField'
import HashField from './SearchBar/HashField'
import PurgedField from './SearchBar/PurgedField'

type FormValues = {
  country: number | undefined
  period: RangePickerValue
  retailers: number[]
  status: number
  type: number
  format: string | null
  categories: string[]
  excludeCategories: boolean
  brands: string[]
  excludeBrands: boolean
  groups: string[]
  excludeGroups: boolean
  is_promotion: number | null
  is_purged: number | null
  is_game: number | null
  hash: string
}

const initialValues: FormValues = {
  country: undefined,
  period: [],
  retailers: [],
  status: 1,
  type: 0,
  format: null,
  categories: [],
  excludeCategories: false,
  brands: [],
  excludeBrands: false,
  groups: [],
  excludeGroups: false,
  is_promotion: 0,
  is_purged: 0,
  is_game: 0,
  hash: '',
}

const formValidatationSchema = Yup.object().shape({
  // retailers: Yup.array().of(Yup.number()).min(1),
  // period: Yup.array().of(Yup.object()).min(2),
})

const queryStringToObject = (searchParams: any) => {
  const keys = Array.from(searchParams.keys())
  const queryParams = keys.reduce((acc: Record<string, any>, key: any) => {
    if (key === 'country') {
      acc[key] = +searchParams.get(key)
    } else if (key === 'hash') {
      acc[key] = searchParams.get(key)
    } else if (key === 'retailer_id') {
      acc['retailers'] = [+searchParams.get(key)]
    }
    return acc
  }, {})
  if (keys.length) {
    queryParams['status'] = BannerStatus.INSERTED
  }
  return queryParams
}

const SearchBar: React.FC<{
  onSearch: (data: any) => Promise<any>
  retailers: any
  countries: number[]
  status?: number
  type?: string
}> = ({ onSearch, retailers, countries, type }) => {
  const [availableRetailers, setAvailableRetailers] = useState<any[]>([])
  const [drawerVisible, setDrawerVisible] = useState<boolean>(true)
  const { setCurrentRequest } = useCurrentRequest()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const queryParams = queryStringToObject(searchParams)

  const handleSearch = async (values: FormValues) => {
    const _values = {
      ...values,
      period: (values.period as []).map((p: Moment) => p.format('YYYY-MM-DD')),
    }
    setCurrentRequest(_values)
    await onSearch(_values)

    setDrawerVisible(false)
  }

  const formik = useFormik({
    initialValues: { ...initialValues, ...queryParams },
    validationSchema: formValidatationSchema,
    onSubmit: handleSearch,
  })

  // Filter selectable retailers for a country
  useEffect(() => {
    setAvailableRetailers(
      filter(retailers, (r) => r.Country == formik.values.country),
    )
  }, [formik.values.country, retailers])

  // Clean selected retailers if country changes
  useEffect(() => {
    // TODO: i don't like this method :/
    if (formik.values.retailers.length && availableRetailers.length) {
      const _sr = formik.values.retailers.filter(
        (sr) => findIndex(availableRetailers, { Id: sr }) !== -1,
      )
      formik.setFieldValue('retailers', _sr)
    }
  }, [availableRetailers])

  const handleOpen = () => {
    setDrawerVisible(true)
  }

  const onClose = () => {
    setDrawerVisible(false)
  }

  return (
    <>
      <Button type="primary" onClick={handleOpen} icon="search">
        Display Search
      </Button>
      <Drawer
        title="Filters"
        placement="left"
        width={360}
        onClose={onClose}
        visible={drawerVisible}
      >
        <Container>
          <CountryField
            formik={formik}
            countries={countries}
            retailers={retailers}
          />
          <RetailersField
            formik={formik}
            availableRetailers={availableRetailers}
          />
          {type === 'restore' && <PurgedField formik={formik} />}
          {type !== 'dashboard' && <TypeField formik={formik} />}
          {type === 'insertion' && <StatusField formik={formik} />}
          <Suspense fallback={<p>Loading...</p>}>
            {[
              BannerStatus.INSERTED,
              BannerStatus.READY_TO_BE_INSERTED,
            ].includes(formik.values.status) &&
              (formik.values.country ? (
                <InsertedFileds formik={formik} countries={countries} />
              ) : (
                <Alert message="Please select a country" type="error" />
              ))}
          </Suspense>
          <PeriodField formik={formik} />
          {type === 'insertion' && <HashField formik={formik} />}
          <Button
            type="primary"
            size="large"
            icon="search"
            onClick={() => formik.handleSubmit()}
            loading={formik.isSubmitting}
          >
            Search
          </Button>
        </Container>
      </Drawer>
    </>
  )
}

export default SearchBar
