import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'components'
import { Typography } from 'antd'

const NotAuthorized: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>401 - Media management - Dataimpact</title>
      </Helmet>
      <Typography.Title>
        You are not authorized to access this page
      </Typography.Title>
    </Container>
  )
}

export default NotAuthorized
