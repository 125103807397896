import api from 'api'
import { useQuery } from 'react-query'

const useUsers = () => {
  return useQuery<any[], Error>('users', api.user.get, {
    suspense: true
    // staleTime: Infinity,
    // cacheTime: 1000 * 60 * 60,
  })
}

export default useUsers
