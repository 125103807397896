import React from 'react'
import styled from 'styled-components'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { Button, Modal } from 'antd'

const ImageZoom: React.FC<{
  image: string
  open: boolean
  onClose: () => void
  className?: string
}> = ({ image, className, onClose, open }) => {
  return (
    <Modal
      visible={open}
      onCancel={onClose}
      width={1000}
      title="ImageZoom"
      footer={null}
      style={{ top: 25 }}
    >
      <TransformWrapper
        defaultScale={1}
        defaultPositionX={200}
        defaultPositionY={100}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }: any) => (
          <div className={className} style={{ maxHeight: '77vh' }}>
            <div className="tools">
              <div>
                <Button
                  size="small"
                  icon="zoom-in"
                  type="dashed"
                  onClick={(e) => {
                    e.stopPropagation()
                    zoomIn(e)
                  }}
                />
                <Button
                  size="small"
                  type="dashed"
                  icon="zoom-out"
                  onClick={(e) => {
                    e.stopPropagation()
                    zoomOut(e)
                  }}
                />
                <Button
                  size="small"
                  type="dashed"
                  icon="fullscreen-exit"
                  onClick={(e) => {
                    e.stopPropagation()
                    resetTransform(e)
                  }}
                />
              </div>
            </div>
            <TransformComponent>
              <img src={image} />
            </TransformComponent>
          </div>
        )}
      </TransformWrapper>
    </Modal>
  )
}

const StyledImageZoom = styled(ImageZoom)`
  cursor: pointer;
  position: relative;
  img {
    width: 100%;
    height: auto;
    max-height: 78vh;
    object-fit: contain;
  }

  div.tools {
    position: absolute;

    display: flex;
    justify-content: space-between;
    padding: 7px 0px 0 6px;
    right: 0;
    left: 0;
    z-index: 10;
    div {
      button {
        margin-right: 3px;
      }
    }
  }

  div.react-transform-component,
  div.react-transform-element {
    width: 100%;
  }
`

export default StyledImageZoom
