import { FormikProps } from 'formik'
import { confirmResetForm } from 'utils/alerts'
import { FormValues } from './useForm'
import { useActiveBannerStore, useBannersStore } from 'containers/Banners/store'
import _ from 'lodash'
import { Banner } from 'types'

const findIndexExtremumValue = (
  activeIds: string[],
  banners: Banner[],
  extremum = 'max',
) => {
  let extremumIndex = -1

  banners.forEach((banner, index) => {
    if (activeIds.includes(banner.id)) {
      if (extremumIndex < 0) {
        extremumIndex = index
      }
      if (
        (extremum === 'max' && index > extremumIndex) ||
        (extremum === 'min' && index < extremumIndex)
      ) {
        extremumIndex = index
      }
    }
  })

  return extremumIndex
}

const useNavigation = (formik: FormikProps<FormValues>) => {
  const banners = useBannersStore((state) => state.banners)
  const activeBannerIds = useActiveBannerStore((state) => state.activeBannerIds)
  const setActiveBannerIds = useActiveBannerStore(
    (state) => state.setActiveBannerIds,
  )
  const handleNavigation = (direction: string) => () => {
    if (formik.dirty) {
      confirmResetForm(() => {
        formik.resetForm()
        direction === 'next' ? onNext() : onPrevious()
      })
    } else {
      direction === 'next' ? onNext() : onPrevious()
    }
  }

  const onNext = () => {
    const nextIndex =
      findIndexExtremumValue(activeBannerIds, banners, 'max') + 1
    if (nextIndex < banners.length) {
      setActiveBannerIds([banners[nextIndex].id])
    }
  }

  const onPrevious = () => {
    const previousIndex =
      findIndexExtremumValue(activeBannerIds, banners, 'min') - 1
    if (previousIndex >= 0) {
      setActiveBannerIds([banners[previousIndex].id])
    }
  }

  return { handleNavigation, onNext }
}

export default useNavigation
