import api from 'api'
import { useQuery } from 'react-query'

const useBannerTypes = () => {
  return useQuery('bannerTypes', api.bannerTypes.get, {
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 60,
    suspense: true,
  })
}

export default useBannerTypes
