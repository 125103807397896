const flattenGroups = (groups: any[]) =>
  groups.map((g: any) => {
    let brand_id
    if (typeof g[1] === 'string') {
      brand_id = g[1]
    } else {
      brand_id = g[1]?.brand_id
    }
    return `${g[0]}-${brand_id}`
  })

export default flattenGroups
